<!--Footer Starts-->
<footer>
    <div class="container-fluid mt-4 mb-4">
        <p class="copyright text-center">
            &copy; {{currentDate | date: 'yyyy'}} <a>WORLD WIDE CAMPERS</a>, BackOffice
            <!--&copy;  <a>PIXINVENT</a>, made with love for a better web-->
        </p>
    </div>
</footer>
<!--Footer Ends-->
