import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatesRoutingModule } from './rates-routing.module';
import { RatesService } from './shared/rates.service';
import { RatesComponent } from './rates.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CampersListComponent } from './campers-list/campers-list.component';
import { CampersRatesComponent } from './campers-rates/campers-rates.component';
import { SharedModule } from 'app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter } from './shared/util/DateAdapter';
import { ToastrModule } from 'ngx-toastr';
import { BreadcrumbsModule, BreadcrumbComponent} from 'ng6-breadcrumbs';
import { BreadcrumbService } from 'ng5-breadcrumb';
import { SupplierListComponent } from './supplier-list/supplier-list.component';
import { CampersManualRatesComponent } from './campers-manual-rates/campers-manual-rates.component';
import { ManualRatesService } from './shared/manual-rates.service';
import { FilterPrice, FilterId, AvailabilityPipe } from './shared/filter.pipe';
import { DiscountCodesComponent } from '../discount-codes/discount-codes.component';
import { DiscountCodeService } from '../discount-codes/shared/discount-code.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RatesRoutingModule,
    NgbModule,
    SharedModule,
    ReactiveFormsModule,
    ToastrModule.forRoot() // ToastrModule added,
  ],
  declarations: [
    RatesComponent,
    CampersListComponent,
    CampersRatesComponent,
    SupplierListComponent,
    CampersManualRatesComponent,
    FilterPrice,
    FilterId,
    AvailabilityPipe
  ],
  providers: [
    RatesService,
    ManualRatesService,
    DateAdapter

  ]
})
export class RatesModule { }
