import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { OpenIdConnectService } from './open-id-connect.service';

@Injectable({
  providedIn: 'root'
})
export class RequiredAuthenticatedUserRouteGuardService implements CanActivate {

  constructor(private opneIdConnectService: OpenIdConnectService,
    private router: Router) { }

    canActivate() {
      if (this.opneIdConnectService.userAvialable ) {
          if (this.opneIdConnectService.userRoleAvailable) {
            return true;
          } else {
            // console.log('BAD ROLE');
            this.opneIdConnectService.triggerSignInBadRole();
            return false;
          }
      } else {
        this.opneIdConnectService.triggerSigmIn();
        return false;
      }
    }
}
