<form>
  <h4 class="form-section" *ngIf="officeList.length > 0"></h4>
  <div class="row mt-2">
      <ul *ngFor="let office of officeList; index as id">
        <div style="width: 300px;">
          <label for="select_{{office.manualFareId}}"> {{ office.name }} </label>
          <select class="form-control custom-select" id="select_{{office.id}}" name="{{office.manualFareId}}"
          #officeTariff (blur)="updateManualFarefOffice(officeTariff.value, office.fareOfficeId, office.id, id)"
          [(ngModel)]='office.manualFareId'  [ngModelOptions]="{standalone: true}">
            <option value="0">No Available</option>
            <option *ngFor="let rate of rateList" value="{{rate.id}}">
                {{ rate.name }}
            </option>
          </select>
        </div>
    </ul>
  </div>
  <h4 class="form-section mt-2"></h4>
</form>

<div class="row ml-0">
    <div class="card-body pt-1">
        <div class="card-block">
            <div class="board">
              <!-- Rates List Tabs where you can choose tariff and create a new one -->
            <div class="row ml-1">
              <a class="addButton add_icon" (click)="addTariff(modalTemplate)" title="New Tariff"></a>
              <a class="duplicate_icon" style="margin-left:10px" (click)="copyNewTariff(modalTemplate)" *ngIf="isEnabled" title="Copy new tariff from other tariff"></a>
              <a class="trash_icon" style="margin-left:10px" (click)="confirmDeleteTariff()" *ngIf="isEnabled" title="Delete"></a>
            </div>
            <div class="myTabs mt-1">
            <ngb-tabset #tabs *ngIf="isEnabled" (tabChange)="beforeChange($event, modalTemplate, tabs)" activeId="activeTabId">
                <ngb-tab *ngFor="let rate of rateList" (select)="selectTab($event)" id="{{rate.id}}">
                    <ng-template ngbTabTitle ><i class="fas fa-euro-sign ml-1"></i><b> {{ rate.name }}</b></ng-template>
                    <ng-template ngbTabContent >
                        <!-- <button class="btn btn-raised btn-outline-primary btn-sm" (click)="addSeasonDate()"><i class="fas fa-plus"></i> Add</button> -->
                        <table class="ml-2 table-striped table-inverse table-responsive table-hover table-fixed mb-2">
                          <thead>
                          <tr>
                              <th class=""> <label>Campers </label> </th>
                              <th class="seasonLabels" *ngFor="let season of mpSeasonList">
                                 <label>{{season.name}}</label>
                              </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="manualPrice-tr mt-2" *ngFor="let camper of mpCamperList; index as id" id="{{camper}}">
                            <td class="manualPrice-label">
                              <label class="mt-1">{{camper.name}}</label>
                            </td>
                            <td class="manualPrice-td" *ngFor="let season of mpList.seasonsList">
                              <input class="form-control mpValue ml-2" type="text" value="{{ mpList.manualPriceList | filterPrice: camper.id: season.id }}"
                              #manualPrice (blur)="updateManualPriceValue(manualPrice.value, manualPrice.id)"
                              id="{{ mpList.manualPriceList | filterId: camper.id: season.id }}">
                           </td>
                          </tr>
                        </tbody>
                        </table>
                        <div class="col-3">
                          <label for="allInclusive">All Inclusive</label>
                          <input type="checkbox" class="boolean" (change)="onChangeAllInclusive($event)" [checked]="rate.allInclusive" id="allInclusive">
                        </div>
                        <a class="addButton add_icon" (click)="addSeasonDate()" title="Add Season Date"></a>
                        <table class="table table-hover table-responsive-md table-sm table-striped">
                          <thead>
                          <tr>
                            <th style="width: 130px;">Actions</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Season</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let seasonDate of seasonDateList, let id = index">
                            <td>
                              <a class="far fa-save list-action-edit text-success"
                              *ngIf="seasonDate.edit || seasonDate.new"
                              (click)="updateSeasonDateList(id)" title="New"></a>
                              <a class="fas fa-ban list-action-edit text-danger"
                              *ngIf="seasonDate.edit || seasonDate.new"
                              (click)="cancelSeasonDate(id)" title="Cancel"></a>
                              <a class="ft-edit list-action text-success"  (click)="editSeasonDate(id)" *ngIf="!seasonDate.edit" title="Edit"></a>
                              <a class="ft-trash-2 list-action text-danger"
                              *ngIf="!seasonDate.edit" (click)="confirmDeleteSeasonDate(seasonDate.id)" title="Delete"></a>
                            </td>
                            <td *ngIf="!seasonDate.edit, else elseBlockSeasonFrom">{{seasonDate.from | date: 'dd/MM/yy'}}</td>
                            <td *ngIf="!seasonDate.edit, else elseBlockSeasonTo">{{seasonDate.to | date: 'dd/MM/yy'}}</td>
                            <td *ngIf="!seasonDate.edit, else elseBlockSeasonName">{{seasonDate.season.name }}</td>
                            <ng-template #elseBlockSeasonFrom>
                              <td>
                              <div class="input-group">
                                  <input class="form-control mt-1" type="text"
                                  value="{{seasonDate.from | date: 'dd/MM/yy'}}"
                                  name="dp" ngbDatepicker #seasonDateFrom="ngbDatepicker" #manualFrom
                                  (blur)="updateManualDate(id, manualFrom.value, 'from')"
                                  (dateSelect)="updateSeasonDateRowDate(id, seasonDateFrom._model , 'from')">
                                  <div class="input-group-append">
                                    <button class="btn btn-outline-secondary mt-1 far fa-calendar-alt" (click)="seasonDateFrom.toggle()" type="button"></button>
                                  </div>
                              </div>
                              </td>
                          </ng-template>
                          <ng-template #elseBlockSeasonTo>
                            <td>
                            <div class="input-group">
                                <input class="form-control mt-1" type="text"
                                value="{{seasonDate.to | date: 'dd/MM/yy'}}"
                                name="dp" ngbDatepicker #seasonDateTo="ngbDatepicker" #manualTo
                                (blur)="updateManualDate(id, manualTo.value, 'to')"
                                (dateSelect)="updateSeasonDateRowDate(id, seasonDateTo._model , 'to')">
                                <div class="input-group-append">
                                  <button class="btn btn-outline-secondary mt-1 far fa-calendar-alt" (click)="seasonDateTo.toggle()" type="button"></button>
                                </div>
                            </div>
                            </td>
                        </ng-template>
                            <ng-template #elseBlockSeasonName>
                                <td>
                                  <div class="input-group">
                                    <label for="select_seasonDate_{{seasonDate.id}}"></label>
                                    <select class="form-control custom-select mt-1" id="select_seasonDate_{{seasonDate.id}}" name="{{seasonDate.id}}"
                                    #seasonDateName (blur)="updateSeasonDateName(seasonDateName.value, id)" value="{{seasonDate.season.id}}"
                                    >
                                      <option value="0">No Available</option>
                                      <option *ngFor="let season of seasonsList" value="{{season.id}}">
                                          {{ season.name }}
                                      </option>
                                    </select>
                                  </div>
                                </td>
                            </ng-template>
                          </tr>
                          </tbody>
                      </table>
                    </ng-template>

                </ngb-tab>
                <!-- <ngb-tab id="first" [disabled]="true">
                  <ng-template ngbTabTitle><b> <i class="fas fa-sliders-h"></i></b> </ng-template>
              </ngb-tab> -->
            </ngb-tabset>
            <div class="form-row">
              <button _ngcontent-c27="" class="btn btn-raised btn-outline-warning mr-2" type="button" (click)="onBack()">
                  <span class="ft-corner-down-left"></span> Return
              </button>
            </div>
          </div>
            </div>
        </div>
    </div>
</div>

<ng-template #modalTemplate let-modal>
    <div class="modal-header">
      <h3 class="modal-title">{{ modalTitle }}</h3>
    </div>
  <div *ngIf="modalType === 'new'">
    <div class="modal-body" >
      <input class="form-control" type="text" placeholder="{{ modalPlaceHolder }}" #tariffName id="tariffName" name="tariffName" value="{{ modalValue }}" >
    </div>
    <div class="modal-footer">
        <button class="btn btn-raised btn-outline-warning mr-2" (click)="confirmNew(tariffName.value)">{{ modalButtonText }}</button>
      </div>
  </div>
  <div *ngIf="modalType === 'copy'">
    <div class="modal-body">
      <select class="form-control" id="selectCopyId" #tariffToCopyId>
        <option *ngFor="let rate of rateList"  value="{{rate.id}}">{{rate.name}}</option>
      </select>
      <div class="modal-header">
          <h3 class="modal-title">Name of New Tariff</h3>
        </div>
      <input class="form-control" type="text" placeholder="{{ modalPlaceHolder }}" #tariffName id="tariffName_copy" name="tariffName" value="{{ modalValue }}" >
    </div>
    <div class="modal-footer">
      <button class="btn btn-raised btn-outline-warning mr-2" id="buttonValue" (click)="confirmCopy(tariffName.value, tariffToCopyId.value)">{{ modalButtonText }}</button>
    </div>
  </div>
</ng-template>

