export abstract class UserAbstractBase {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    isB2CUser: boolean;
    enable: boolean;
    role: string[];
    address: string;
    postalCode: string;
    country: string;
    city: string;
    phoneNumber: string;
    emailConfirmed: boolean;
    }
