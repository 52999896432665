import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  apiRoot = environment.apiRoot;
  apiUrl = environment.apiUrl;
  apiUrlImage = environment.apiUrlImage;
  apiUsersUrl = environment.apiUsersUrl;
  apiReport = environment.apiReports;
  resetUrl = environment.resetUrl;
  isDev = environment.isDev;
  production = environment.production;
  constructor() { }
}
