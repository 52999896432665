export class Rate {
  id: string;
  allInclusive: boolean;
  name: string;
}

export class RateToCreation {
  name: string;
  camperId: number;
}
export class ManualRateToCreation {
  name: string;
  supplierId: number;
}
