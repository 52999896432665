import { Component, OnInit, OnDestroy } from '@angular/core';
import { RatesService } from '../shared/rates.service';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { Supplier } from '../shared/suppliers.model';
import { BreadcrumbsService } from 'ng6-breadcrumbs';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.scss']
})
export class SupplierListComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();
  supplierList: Array<Supplier>;
  totalCount: number;
  totalPages: number;
  pageSize = 1;
  page = 1;
  currentPage = 1;
  isSearched = false;
  searchValue = '';
  orderBy = 'Name';
  reverse = false;
  camperName: string;
  constructor(
    private ratesService: RatesService,
    private config: NgbPaginationConfig,
    private breadcrumbsService: BreadcrumbsService
    ) {config.boundaryLinks = true; }

    ngOnInit() {
      this.breadcrumbsService.store([{label: 'Rates' , url: '/rates', params: []}, {label: 'Supplier List' , url: '/', params: []}])
      this.supplierList = new Array<Supplier>();
      if (this.ratesService.goBack && this.ratesService.searchValue !== undefined) {
        this.searchValue = this.ratesService.searchValue;
        console.log('last');
        this.getLastUrl();
      } else {
        this.searchValue = '';
        this.getSupplierList();
      }
      this.ratesService.goBack = false;
      // this.ratesService.readOldBasePrices().subscribe(data => {
      //   console.log(data);
      // })
    }
    ngOnDestroy() {
      this.subscriptions.unsubscribe();
    }
    // public emitName(name: string): string {
    //   console.log('Emit ->', name);
    //   this.emitEvent.emit(name);
    //   console.log(this.emitEvent);
    //   this.camperName = name;
    //   return name;
    // }
    getLastUrl() {
      this.subscriptions.add(
        this.ratesService.getLastUrlSupplier().subscribe(resp => {
          this.supplierList = resp.body;
          const xPagination = JSON.parse(resp.headers.get('x-pagination'));
          this.pageSize = xPagination.pageSize;
          this.totalPages = (xPagination.totalPages * 10) / 1;
          this.page = xPagination.currentPage;
          this.ratesService.lastUrl = resp.url;
        })
      )
    }
    getSupplierList() {
      this.subscriptions.add(
        this.ratesService.getSuppliers().subscribe(resp => {
          const xPagination = JSON.parse(resp.headers.get('x-pagination'));
          // console.log(xPagination);
          this.pageSize = xPagination.pageSize;
          this.totalPages = (xPagination.totalPages * 10) / 2;
          this.getSupplierListByPage(this.page);
        }, error => { console.log(); } )
      )
    }
    getSupplierListByPage(pageToGet) {
      this.subscriptions.add(
        this.ratesService.getSuppliersByPage(this.pageSize * 2, pageToGet,this.orderBy,this.reverse).subscribe(data => {
          this.supplierList = data.body;
          this.ratesService.lastUrl = data.url;
        }, error => { console.log(error); } )
      )
    }
    onPager(event: number) {
      if ( this.isSearched ) {
        this.getSupplierListByPagedSearch(this.searchValue, event);
      } else {
        this.getSupplierListByPage(event);
      }
    }
    getSupplierListByPagedSearch(value: string, pageToGet: number) {
      this.subscriptions.add(
        this.ratesService.getSuppliersByPagedSearch(value, this.pageSize * 2, pageToGet,this.orderBy,this.reverse).subscribe( resp => {
          this.supplierList = (resp.body);
          console.log(resp.headers);
          const xPagination = JSON.parse(resp.headers.get('x-pagination'));
          this.pageSize = xPagination.pageSize;
          this.totalPages = xPagination.totalPages * 10;
          this.ratesService.lastUrl = resp.url;
        })
      )
    }
    onSearch(value: string) {
      this.ratesService.searchValue = value;
      if ( value.length > 3 ) {
        this.searchValue = value;
        this.isSearched = true;
        this.getCampersBySearch(value);
      }
      if (value.length === 3 && this.isSearched) {
        this.getSupplierList();
        this.isSearched = false;
      }
      if (this.supplierList.length === 0 && value.length < 3) {
        this.getSupplierList();
      }
      if (value.length === 0 && this.supplierList.length !== 0) {
        if (this.isSearched) {
          this.getSupplierList();
          this.isSearched = false;
        }
      }
    }
    getCampersBySearch(value: string) {
      this.subscriptions.add(
        this.ratesService.getSuppliersBySearch(value,this.orderBy,this.reverse).subscribe( resp => {
          this.supplierList = (resp.body);
          const xPagination = JSON.parse(resp.headers.get('x-pagination'));
          this.pageSize = xPagination.pageSize;
          this.totalPages = xPagination.totalPages * 10;
          this.ratesService.lastUrl = resp.url;
        })
      );
    }
    updateFlexRatesState(id: number, index: number) {
    //  console.log(this.supplierList[index].useFlexRates);
    this.subscriptions.add(
      this.ratesService.updateFlexRatesState(id).subscribe(data => {
        this.supplierList[index].useFlexRates = data.useFlexRates
        console.log(data);
      })
    )
    }
    sameModule() {
      this.ratesService.sameModule = true;
    }
    orderColumn(val: string) {
      if(val == this.orderBy) this.reverse = !this.reverse;
      this.orderBy = val;
      if ( this.isSearched ) {
        this.getSupplierListByPagedSearch(this.searchValue, 1);
      } else {
        this.getSupplierListByPage(1);
      }
    }
}
