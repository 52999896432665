import { Component } from '@angular/core';
import {OpenIdConnectService} from './shared/open-id-connect.service';


//declare var tinymce: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {

    constructor(private openIdConnectService: OpenIdConnectService) {

    }
}
