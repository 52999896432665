<div class="wrapper nav-collapsed menu-collapsed" [dir]="options.direction">
   <div class="app-sidebar" data-active-color="white" data-background-color="warning" data-image="assets/img/sidebar-bg/01.jpg">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background"></div>
    </div>
    <app-navbar></app-navbar>
    <div class="main-panel">
        <div class="main-content">
            <div class="content-wrapper">
                <div class="container-fluid">
                    <div class="mb-3">
                        <breadcrumb [allowBootstrap]="true"></breadcrumb>
                    </div>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
</div>


