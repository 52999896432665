import { Component, OnInit, TemplateRef, ViewChild, EventEmitter, Output, QueryList, OnDestroy } from '@angular/core';
// import { BsModalService } from 'ngx-bootstrap/modal';
// import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RatesService } from '../shared/rates.service';

import swal from 'sweetalert2';
import { Rate } from '../shared/rates.model';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbTabChangeEvent, NgbTabset, NgbTab } from '@ng-bootstrap/ng-bootstrap';
import { BasePrice, BasePriceToCreate } from '../shared/basePrice.model';
// import { stringify } from '@angular/compiler/src/util';
// import { config } from 'rxjs';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { DateAdapter } from '../shared/util/DateAdapter';
import { Office, FareOffice } from '../shared/office.model';
import { BreadcrumbsService } from 'ng6-breadcrumbs';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { LocationService } from 'app/shared/services/location.service';
// import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-campers-rates',
  templateUrl: './campers-rates.component.html',
  styleUrls: ['./campers-rates.component.scss'],
  providers: [NgbTabset]
})
export class CampersRatesComponent implements OnInit, OnDestroy {

  @Output() emitEvent = new EventEmitter<string>();
  componentName = 'CamperRates';
  _camperId: string;
  _supplierId: number;
  modalRef: NgbModalRef;
  rateList: Array<Rate>;
  basePriceList: Array<BasePrice>;
  modalTitle: string;
  modalType: string;
  modalPlaceHolder: string;
  modalButtonText: string;
  modalValue: string;
  modalOpen: boolean;
  newTariffTabId: string;
  activeTabId: string;
  activeTabName: string;
  tEvent: NgbTabset;
  nextTabId: string;
  model;
  isEnabled: boolean;
  camperName: string;
  supplierName: string;
  officeList: Array<Office>;
  closeResult: string;
  tariffSelected: number;
  tariffName: string;
  allInclusive: boolean;
  @ViewChild('tabs')
  private tabs: NgbTabset;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private modalService: NgbModal,
    private ratesService: RatesService,
    private _route: ActivatedRoute,
    private _router: Router,
    private tabSet: NgbTabset,
    private dateAdapter: DateAdapter,
    private breadcrumbsService: BreadcrumbsService,
    private location: Location,
    private locationService: LocationService
    ) {}

  ngOnInit() {
    this._camperId = this._route.snapshot.paramMap.get('id');
    this.breadcrumbsService.store([{label: 'Rates' , url: '/rates', params: []},
    {label: 'Supplier List' , url: '/rates', params: []}, {label: 'Campers List' , url: '/rates', params: []},
    {label: 'Camper Rates Editior' , url: '/', params: []}])
    this.modalOpen = false;
    this.modalValue = 'Tariff';
    this.tabSet.tabs = new QueryList<NgbTab>();
    this.rateList = new Array<Rate>();
    this.basePriceList = new Array<BasePrice>();
    this._camperId = this._route.snapshot.paramMap.get('id');
    this.subscriptions.add(
      this.ratesService.getCampersV1ById(Number.parseInt(this._camperId, 10)).subscribe(camper => {
        this.camperName = camper.body.name;
        this.supplierName = camper.body.supplierName;
        this._supplierId = camper.body.supplierId;
        console.log(this._supplierId);
        this.emitEvent.emit(this.camperName + ' - ' + this.supplierName);
        this.getOfficeList();
      })
      )
    this.getTariffList();
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  selectTab(event: any) {
    console.log(event);
    this.getBasePriceList(event.target.value);
    this.activeTabId = event.target.value;
    const tabIdRow = this.rateList.indexOf(
      this.rateList.find(function (rate) {
        return rate.id === event.target.value;
      }));
    this.activeTabName = this.rateList[tabIdRow].name;
    this.tariffName = this.rateList[tabIdRow].name;
    this.allInclusive = this.rateList[tabIdRow].allInclusive;
  }

  onChangeAllInclusive(event: any){
    this.rateList.find(x => x.id == this.activeTabId).allInclusive =  event.target.checked;
    this.ratesService.updateAllInclusive(this.activeTabId, event.target.checked).subscribe(data =>{
      console.log("allInclusive: " + event.target.checked);
    });
  }

  select(tabId: string) {
    console.log('Select - ', tabId);
  }
  openModal(template: TemplateRef<any>) {
    this.modalOpen = true;
    this.modalRef = this.modalService.open(template);
    this.modalRef.result.then(result => {
    }, (reason) => {
      this.closeResult = 'Dismissed';
    });
  }

  decline(): void {
    this.modalRef.close();
    };

  newSelect(t: NgbTabset) {
    t.select(t.activeId);
  }
  beforeChange($event: NgbTabChangeEvent, template: TemplateRef<any>, tabs: NgbTabset) {
    this.tabSet = tabs;
    const tabIdName = $event.nextId;
    this.modalOpen = true;
    this.selectModal(tabIdName, template);
    $event.nextId = this.nextTabId;
    this.activeTabId = tabIdName;
    const tabIdRow = this.rateList.indexOf(
      this.rateList.find(function (rate) {
        return rate.id === tabIdName;
      }));

    this.activeTabName = this.rateList[tabIdRow].name;
    this.tariffName = this.rateList[tabIdRow].name;
  };

  selectModal(tabIdName: string, template: TemplateRef<any>) {
    if (tabIdName === 'new') {
      console.log('new')
      this.modalTitle = 'New Tariff';
      this.modalPlaceHolder = 'Name of New Tariff';
      this.modalType = 'new';
      this.modalButtonText = 'Submit!';
      this.openModal(template);
    } else if (tabIdName === 'copy') {
      console.log('copy')
      this.modalTitle = 'Copy Tariff From';
      this.modalType = 'copy';
      this.modalButtonText = 'Submit!'
      this.openModal(template);
    } else {
      this.getBasePriceList(tabIdName);
    }
  }
  onBack(): void {
    // this.ratesService.goBack = true;
    //this._router.navigate(['/rates/camperList/' + this._supplierId]);
    this.locationService.goBack = true;
    this.location.back();
  }
  // OFFICE SECTION --------------------------------------------------------------------------------------------------

  getOfficeList() {
    this.subscriptions.add(
      this.ratesService.getOfficeList(this._supplierId, this._camperId).subscribe(data => {
        this.officeList = data;
      })
    )
  }

  updateTariffOffice(value: string, fareOfficeId: string, officeId: number, index: number) {
    // console.log('tariff id -> ', this.officeList[index]);
    // console.log('value ->', value); // Fare Id
    // console.log('FareOffice id -> ', fareOfficeId); // Row DB Id
    // console.log('Office Id -> ', officeId); // Office Id
    this.subscriptions.add(
      this.ratesService.updateFareOffice(fareOfficeId, value, officeId).subscribe(resp => {
        // console.log(resp);
        this.officeList[index].fareOfficeId = resp.id;
        // this.toastr.success(this.officeList[index].name + ' Tariff Updated', 'Success!');
      })
    )
  }

  // END OFFICE SECTION ----------------------------------------------------------------------------------------------
  // BASE PRICE SECTION ----------------------------------------------------------------------------------------------
  getBasePriceList(idTariff: string) {
    this.subscriptions.add(
      this.ratesService.getBasePrice(idTariff).subscribe( data => {
        this.basePriceList = data;
        this.basePriceList.forEach(basePrice => {
          basePrice.fromBackUp = basePrice.from;
          basePrice.toBackUp = basePrice.to;
          basePrice.priceBackUp = basePrice.price;
          // basePrice.preparationFeeBackUp = basePrice.preparationFee;
        })
      })
    )
  }
  addBasePrice() {
    const newBasePrice = new BasePriceToCreate();
    newBasePrice.new = true;
    newBasePrice.edit = true;
    newBasePrice.fareId = this.activeTabId;
    this.basePriceList.unshift(newBasePrice);
  }
  editBasePrice(basePriceId: string, rowId: number) {
    console.log(basePriceId, rowId);
    this.basePriceList[rowId].edit = !this.basePriceList[rowId].edit;
  }
  confirmDeleteBasePrice(id: string) {
    swal({
        title: 'Are you sure?',
        text: 'You are going to delete a base price',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0CC27E',
        cancelButtonColor: '#FF586B',
        confirmButtonText: 'Delete Base Price',
        cancelButtonText: 'No, cancel'
    }).then((value) => {
      console.log(value);
      if (!value.dismiss) {
        this.deleteBasePrice(id);
      }
    }).catch(swal.noop);
}
  deleteBasePrice(id: string) {
    this.subscriptions.add(
    this.ratesService.deleteBasePrice(id).subscribe(data => {
      swal('Deleted!', 'Base Price deleted!', 'success')
      this.getBasePriceList(this.activeTabId);
    })
    )
  }
  updateBasePriceList(idRow: number) {
    if (this.basePriceList[idRow].new) {
      const newBasePrice = this.basePriceList[idRow];
      this.subscriptions.add(
        this.ratesService.newBasePrice(newBasePrice).subscribe( data => {
          this.basePriceList[idRow] = data;
          this.getBasePriceList(this.activeTabId);
          // swal('Created Success!', 'Base Price Created', 'success')
          // this.toastr.success('Base Price Created', 'Success!');
        }, (validationResult) => {
          // ValidationErrorHandler.handleValidationErrors(this.userForm, validationResult.error);
          console.log(validationResult);
        })
      );
      console.log('works -> ', this.basePriceList[idRow]);

    } else {
      const basePriceToUpdate = this.basePriceList[idRow];
      this.basePriceList[idRow].edit = !this.basePriceList[idRow].edit;

      this.ratesService.updateBasePrice(basePriceToUpdate.id, basePriceToUpdate).subscribe(data => {
        this.basePriceList[idRow] = data;
        // this.toastr.success('Base Price Updated', 'Update Success!');

      })
      console.log('works -> ', this.basePriceList[idRow]);
    }

  }
  cancelUpdateBasePrice(idRow: number ) {
    console.log('test', this.basePriceList[idRow]);
    if (this.basePriceList[idRow].new === true) {
      console.log('delete', idRow);
      console.log(this.basePriceList[idRow]);
      this.basePriceList.splice(idRow, 1);
     } else {
      console.log(this.basePriceList[idRow].fromBackUp);
      this.basePriceList[idRow].price = this.basePriceList[idRow].priceBackUp;
      this.basePriceList[idRow].from = this.basePriceList[idRow].fromBackUp;
      this.basePriceList[idRow].to = this.basePriceList[idRow].toBackUp;
      this.basePriceList[idRow].edit = !this.basePriceList[idRow].edit;
      // this.basePriceList[idRow].preparationFee = this.basePriceList[idRow].preparationFeeBackUp;
     }
  }
  updateManualDate (idRow: number, date: string, myProp: string) {
    console.log('date ->' + date);
    const newDate = new Date();
    const dateArray = date.split('/');
    const day = dateArray[0];
    const month = dateArray[1];
    let year = dateArray[2];
    if (year.length < 3) {
      year = '20' + year;
    }
    console.log(year + '-' + month + '-' + day);
    // const newDate = this.dateAdapter.toModel(date);
    this.basePriceList[idRow][myProp] = year + '-' + month + '-' + day;
    // console.log('row -> ', this.basePriceList[idRow][myProp]);
  }
  updateBasePriceRowDate(idRow: number, date: NgbDate, myProp: string) {
    console.log('date ->' + date);
    const newDate = this.dateAdapter.toModel(date);
    this.basePriceList[idRow][myProp] = date.year + '-' + date.month + '-' + date.day;
    console.log('row -> ', this.basePriceList[idRow][myProp]);
  }
  updateBasePriceRow(idRow: number, value: number) {
    this.basePriceList[idRow].price = value;
    console.log('row -> ', this.basePriceList[idRow]);
  }
  // updateBasePriceRowFee(idRow: number, value: number) {
  //   this.basePriceList[idRow].preparationFee = value;
  //   console.log('row -> ', this.basePriceList[idRow]);
  // }
  updateBasePriceRowAvailability(idRow: number, value: string) {
    this.basePriceList[idRow].availability = value;
    console.log('row -> ', this.basePriceList[idRow]);
  }
  // END BASE PRICE SECTION ----------------------------------------------------------------------------------------------
  // TARIFF SECTION ------------------------------------------------------------------------------------------------------
  confirmNew(name: string): void {
    this.modalRef.close();
    this.modalOpen = false;
    this.newTariff(name);
  }
  confirmCopy(name: string, tarifftoCopyId): void {
    // this.modalRef.hide();
    this.modalOpen = false;
    this.copyTariff(tarifftoCopyId, name);
  }
  getTariffList() {
    console.log('get Tariffs');
    this.subscriptions.add(
      this.ratesService.getFaresByCamper(this._camperId).subscribe(data => {
        if ( data.length !== 0) {
          this.rateList = data;
          this.activeTabId = this.rateList[0].id;
          this.activeTabName = this.rateList[0].name;
          this.allInclusive = this.rateList[0].allInclusive;
          this.tariffName = this.rateList[0].name;
          this.isEnabled = true;
          this.getBasePriceList(this.rateList[0].id);
        } else {
          this.isEnabled = false;
          this.rateList = null;
        }
      })
    )
  }
  newTariff(name: string) {
    // this.modalRef.hide();
    console.log(this.tabSet.activeId);
    console.log('esto - ', this.tabSet);
    this.subscriptions.add(
      this.ratesService.newTariff(name, this._camperId).subscribe(data => {
        this.newTariffTabId = data.id;
        // console.log('New tariff result -> ', this.newTariffTabId);
        // swal('Success!', name + ' Created!', 'success')
        // this.toastr.success(name + ' Created', 'Success!');

        this.getTariffList();
        setTimeout(() => {
          this.tabSet.select(this.newTariffTabId);
          this.activeTabName = this.rateList[0].name;
        }, 500);
      })
    )
    console.log(name, this._camperId);
  }
  copyTariff(tarifftoCopyId: string, name: string) {
    this.modalRef.close();
    this.subscriptions.add(
      this.ratesService.copyTariff(name, this._camperId, tarifftoCopyId).subscribe(data => {
        // console.log(data);
        // this.toastr.success(name + ' Created', 'Success!');
        this.getTariffList();
        setTimeout(() => {
          this.tabSet.select(data.id);
        }, 500);
      })
    )
    console.log('tarifftoCopyId -> ', tarifftoCopyId + ' ' + name );
  }
  deleteTariff(id: string, name: string) {
    this.subscriptions.add(
      this.ratesService.deleteTariff(id).subscribe(data => {
        swal('Deleted!', name + ' deleted!', 'success')
        this.getTariffList();
      })
    )
  }
  addTariff(template: TemplateRef<any>) {
    console.log('new')
    this.modalTitle = 'New Tariff';
    this.modalPlaceHolder = 'Name of New Tariff';
    this.modalType = 'new';
    this.modalButtonText = 'Submit!';
    this.openModal(template);
  }
  copyNewTariff(template: TemplateRef<any>) {
    console.log('copy')
    this.modalTitle = 'Copy Tariff From';
    this.modalType = 'copy';
    this.modalButtonText = 'Submit!'
    this.openModal(template);
  }
  confirmDeleteTariff() {
    // if (this.activeTabName !== undefined ) {
      swal({
        title: 'Are you sure?',
        text: 'You are going to delete ' + this.activeTabName ,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0CC27E',
        cancelButtonColor: '#FF586B',
        confirmButtonText: 'Delete Tariff',
        cancelButtonText: 'No, cancel'
    }).then((value) => {
      console.log(value);
      if (!value.dismiss) {
        this.deleteTariff(this.activeTabId, this.activeTabName);
      }
    }).catch(swal.noop);
    // }
  }
  // END TARIFF SECTION ---------------------------------------------------------------------------------------------------

}

