<nav class="header-navbar navbar navbar-expand-lg navbar-light bg-faded">
    <div class="container-fluid">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle d-lg-none float-left" data-toggle="collapse">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <span class="d-lg-none navbar-right navbar-collapse-toggle">
                <a class="open-navbar-container" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="navbarSupportedContent">
                    <i class="ft-more-vertical"></i>
                </a>
            </span>
            <!-- <form class="navbar-form navbar-right mt-1" role="search">
                <div class="position-relative has-icon-right">
                    <input type="text" class="form-control round" placeholder="Search">
                    <div class="form-control-position">
                        <i class="ft-search"></i>
                    </div>
                </div>
            </form> -->
        </div>
        <div class="navbar-container">
            <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
                {{userNameDisplay}}
                <ul class="navbar-nav">

                    <li class="nav-item" ngbDropdown [placement]="placement">
                        <a class="nav-link position-relative" id="dropdownBasic3"  ngbDropdownToggle>
                            <i class="ft-user font-medium-3 blue-grey darken-4"></i>
                            <p class="d-none">User Settings</p>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="text-left">
                            <a class="dropdown-item py-1" href="javascript:;">
                                <i class="ft-edit mr-2"></i>
                                <span>Edit Profile</span>
                            </a>
                            <div class="dropdown-divider"></div>
                            <a  class="dropdown-item" (click)="openIdConnectService.triggerSignOut()" >
                                <i class="ft-power mr-2"></i>
                                <span>Logout</span>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
