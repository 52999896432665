import { Component } from '@angular/core';
import { OpenIdConnectService } from '../open-id-connect.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent {

    toggleClass = 'ft-maximize';
    placement = 'bottom-right'
    public isCollapsed = true;
    private isAdmin : boolean = (this.openIdConnectService.user.profile.role === 'Administrator');
    userNameDisplay: string = this.openIdConnectService.user.profile.name;

    constructor(public openIdConnectService: OpenIdConnectService) {

    }

    ToggleClass() {
        if (this.toggleClass === 'ft-maximize') {
            this.toggleClass = 'ft-minimize';
        } else {
            this.toggleClass = 'ft-maximize'
        }
    }
}
