import { Injectable } from '@angular/core';
import {UserManager, User, Log} from 'oidc-client';
import { environment } from 'environments/environment';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpenIdConnectService {

  private userManager: UserManager = new UserManager(environment.openIdConnectSettings);
  private currentUser: User;

  userLoaded$ = new ReplaySubject<boolean>(1);

  get userAvialable(): boolean {
    return this.currentUser != null;
  }
  get userRoleAvailable(): boolean {
    if (this.currentUser.profile.role === 'Administrator') {
      return true;
    } else {
      return false;
    }
  }
  get user(): User {
    return this.currentUser;
  }

  constructor() {

    Log.logger = console;

    this.userManager.clearStaleState();

      this.userManager.getUser().then(user => {
          this.currentUser = user;
       });

    this.userManager.events.addUserLoaded(user => {
      if (!environment.production) {
        console.log('User loaded.', user);
      }
      this.currentUser = user;
      this.userLoaded$.next(true);
    })

    this.userManager.events.addUserUnloaded((e) => {
      if (!environment.production) {
        console.log('User unloaded');
      }
      this.currentUser = null;
      this.userLoaded$.next(false);
    })

  }

  triggerSigmIn() {
    this.userManager.signinRedirect().then(function() {
      if(!environment.production){
        console.log('Redirect into signin triggered.');
      }
    });
  }

  triggerSignInBadRole() {
    this.userManager.events.removeUserLoaded(user => {
      this.userManager.signinRedirect().then(function() {
        if(!environment.production){
          console.log('Redirect into signin triggered.');
        }
      });
    });
  }

  handleCallback(){
    console.log('handleCallback init');

    return this.userManager.signinRedirectCallback().then(user => {
      this.currentUser = user;
    });

  }

  handleSilentCallback() {
    this.userManager.signinSilentCallback().then(function (user) {
      console.log('SilentCall - Refresh');
      //this.currentUser = user
      if (!environment.production) {
        console.log('Callback after silent signin handled.', user);
      }
    });
  }

  triggerSignOut(){
    this.userManager.signoutRedirect().then(function (resp){
      if(!environment.production){
        console.log('Redirection to signout triggered', resp)
      }
    });
  };

}
