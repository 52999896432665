<form>
    <div class="form-row" style="float:right;margin-top:-50px;">
        <button _ngcontent-c27="" class="btn btn-raised btn-outline-warning mr-2" type="button" (click)="onBack()">
  <span class="ft-corner-down-left"></span> Return
</button>
    </div>
    <h4 class="form-section"></h4>
    <div class="row mt-2">
        <table class="table table-hover table-responsive-md table-sm">
            <thead>
                <tr>
                    <th>City</th>
                    <th>Tariff</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let office of officeList; index as id">
                    <td> {{ office.name }} </td>
                    <td><select class="form-control custom-select" id="select_{{office.tariffId}}" name="{{office.tariffId}}" #officeTariff (blur)="updateTariffOffice(officeTariff.value, office.fareOfficeId, office.id, id)" [(ngModel)]='office.tariffId' [ngModelOptions]="{standalone: true}">
                        <option value="00000000-0000-0000-0000-000000000000">No Available</option>
                        <option *ngFor="let rate of rateList" value="{{rate.id}}">
                            {{ rate.name }}
                        </option>
                      </select>
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- <ul *ngFor="let office of officeList; index as id">
            <div style="width: 300px;">
                <label for="select_{{office.tariffId}}"> {{ office.name }} </label>
                <select class="form-control custom-select" id="select_{{office.tariffId}}" name="{{office.tariffId}}" #officeTariff (blur)="updateTariffOffice(officeTariff.value, office.fareOfficeId, office.id, id)" [(ngModel)]='office.tariffId' [ngModelOptions]="{standalone: true}">
            <option value="00000000-0000-0000-0000-000000000000">No Available</option>
            <option *ngFor="let rate of rateList" value="{{rate.id}}">
                {{ rate.name }}
            </option>
          </select>
            </div>
        </ul> -->
    </div>
    <h4 class="form-section mt-2"></h4>
</form>

<div class="row ml-0">
    <div class="card-body pt-1">
        <div class="card-block">
            <div class="board">
                <!-- Rates List Tabs where you can choose tariff and create a new one -->
                <div class="row">
                    <div class="col-6">
                        <form> <label for="tariffDropDown"> Select a Tariff</label></form>
                        <select class="form-control custom-select" #tariffDD id="tariffDropDown" (change)="selectTab($event)">
                  <option *ngFor="let rate of rateList" value="{{rate.id}}">
                      {{ rate.name }}
                  </option>

                  
                </select>
                <br>
                <br>
                <div class="col-3">
                    <label for="allInclusive">All Inclusive</label>
                    <input type="checkbox" class="boolean" (change)="onChangeAllInclusive($event)" [checked]="allInclusive" id="allInclusive">
                </div>
                    </div>
                    <div class="col-6 mt-4">
                        <div class="row ml-1">
                            <a class="add_icon" style="margin-left: 10px;" (click)="addTariff(modalTemplate)" title="New Tariff"></a>
                            <a class="duplicate_icon" style="margin-left:10px" (click)="copyNewTariff(modalTemplate)" *ngIf="isEnabled" title="Copy new tariff from other tariff"></a>
                            <a class="trash_icon" style="margin-left:10px" (click)="confirmDeleteTariff()" *ngIf="isEnabled" title="Delete"></a>
                        </div>
                    </div>
                </div>
                <div class="myTabs mt-1">



                    <!-- <ngb-tabset #tabs *ngIf="isEnabled" (tabChange)="beforeChange($event, modalTemplate, tabs)" activeId="activeTabId">
                <ngb-tab *ngFor="let rate of rateList" (select)="selectTab($event)" id="{{rate.id}}">
                    <ng-template ngbTabTitle ><i class="fas fa-euro-sign ml-1"></i><b> {{ rate.name }}</b></ng-template> -->
                    <!-- <ng-template ngbTabContent > -->
                    <a class="addButton add_icon" (click)="addBasePrice()" title="Add Base Price"></a>
                    <form>
                        <h4 class="form-section">{{ tariffName }}</h4>
                    </form>
                    <table class="table table-hover table-responsive-md table-sm table-striped">
                        <thead>
                            <tr>
                                <th style="width: 130px;">Actions</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Base Price</th>
                                <!-- <th>Preparation Fee</th> -->
                                <th>Availability</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let basePrice of basePriceList, index as id">
                                <td>
                                    <div style="width: 130px;">
                                        <a class="far fa-save list-action-edit text-success" *ngIf="basePrice.edit || basePrice.new" (click)="updateBasePriceList(id)" title="New"></a>
                                        <a class="fas fa-ban list-action-edit text-danger" *ngIf="basePrice.edit || basePrice.new" (click)="cancelUpdateBasePrice(id)" title="Cancel"></a>
                                        <a class="ft-edit list-action text-success" (click)="editBasePrice(basePrice.id, id)" *ngIf="!basePrice.edit" title="Edit"></a>
                                        <a class="ft-trash-2 list-action text-danger" *ngIf="!basePrice.edit" (click)="confirmDeleteBasePrice(basePrice.id)" title="Delete"></a>
                                    </div>
                                </td>
                                <td *ngIf="!basePrice.edit, else elseBlockFrom">{{ basePrice.from | date: 'dd/MM/yy'}}</td>
                                <ng-template #elseBlockFrom>
                                    <td>
                                        <div class="input-group">
                                            <input class="form-control mt-1" type="text" value="{{basePrice.from | date: 'dd/MM/yy'}}" name="dp" ngbDatepicker #basePriceFrom="ngbDatepicker" #manualFrom (blur)="updateManualDate(id, manualFrom.value, 'from')" (dateSelect)="updateBasePriceRowDate(id, basePriceFrom._model , 'from')">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary mt-1 far fa-calendar-alt" (click)="basePriceFrom.toggle()" type="button"></button>
                                            </div>
                                        </div>
                                        <!-- <input class="form-control m-1" #basePriceFrom type="text" value="{{basePrice.from}}"
                                    (blur)="updateBasePriceRow(id, basePriceFrom.value , 'from')"> -->
                                    </td>
                                </ng-template>
                                <td *ngIf="!basePrice.edit, else elseBlockTo">{{ basePrice.to | date: 'dd/MM/yy' }}</td>
                                <ng-template #elseBlockTo>
                                    <td>
                                        <div class="input-group">
                                            <input class="form-control mt-1" type="text" value="{{basePrice.to | date: 'dd/MM/yy'}}" name="dp" ngbDatepicker #basePriceTo="ngbDatepicker" #manualTo (blur)="updateManualDate(id, manualTo.value, 'to')" (dateSelect)="updateBasePriceRowDate(id, basePriceTo._model , 'to')">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary mt-1 far fa-calendar-alt" (click)="basePriceTo.toggle()" type="button"></button>
                                            </div>
                                        </div>
                                        <!-- <input class="form-control m-1" #basePriceTo type="text" value="{{basePrice.to}}"
                                    (blur)="updateBasePriceRow(id, basePriceTo.value , 'to')"> -->
                                    </td>
                                </ng-template>
                                <td *ngIf="!basePrice.edit, else elseBlockPrice">{{ basePrice.price | number:'1.0-2' }}</td>
                                <ng-template #elseBlockPrice>
                                    <td>
                                        <input class="form-control m-1" #basePricePrice type="text" value="{{basePrice.price | number:'1.0-2'}}" (blur)="updateBasePriceRow(id, basePricePrice.value)">
                                    </td>
                                    <!-- (blur)="updateBasePrice(basePrice.id, elseBlockPrice.value, id)" -->
                                </ng-template>
                                <!-- <td (click)='editBasePrice(basePrice.id, id)' *ngIf="!basePrice.edit, else elseBlockFee">{{ basePrice.preparationFee | number:'1.0-2' }}</td>
                              <ng-template #elseBlockFee>
                                  <td>
                                    <input class="form-control m-1" #basePriceFee type="text" value="{{basePrice.preparationFee | number:'1.0-2'}}"
                                    (blur)="updateBasePriceRowFee(id, basePriceFee.value)">
                                  </td> -->
                                <!-- (blur)="updateBasePrice(basePrice.id, elseBlockPrice.value, id)" -->
                                <!-- </ng-template> -->
                                <td *ngIf="!basePrice.edit, else elseBlockAvailability">{{ basePrice.availability | availability}}</td>
                                <ng-template #elseBlockAvailability>
                                    <td>
                                        <div>
                                            <select class="form-control custom-select m-1" name="{{basePrice.id}}" #availability (blur)="updateBasePriceRowAvailability(id, availability.value)" [(ngModel)]='basePrice.availability' [ngModelOptions]="{standalone: true}">
                                        <option value="FS">FS - Available</option>
                                        <option value="RQ">RQ - On Request</option>
                                      </select>
                                        </div>
                                    </td>
                                    <!-- (blur)="updateBasePrice(basePrice.id, elseBlockPrice.value, id)" -->
                                </ng-template>
                            </tr>
                        </tbody>
                    </table>
                    <!-- </ng-template>
                </ngb-tab> -->
                    <!-- <ngb-tab id="first" [disabled]="true">
                  <ng-template ngbTabTitle><b> <i class="fas fa-sliders-h"></i></b> </ng-template>
              </ngb-tab> -->
                    <!-- </ngb-tabset> -->
                    <div class="form-row">
                        <button _ngcontent-c27="" class="btn btn-raised btn-outline-warning mr-2" type="button" (click)="onBack()">
                  <span class="ft-corner-down-left"></span> Return
              </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #modalTemplate let-modal>
    <div class="modal-header">
        <h3 class="modal-title">{{ modalTitle }}</h3>
    </div>
    <div *ngIf="modalType === 'new'">
        <div class="modal-body">
            <input class="form-control" type="text" placeholder="{{ modalPlaceHolder }}" #tariffName id="tariffName" name="tariffName" value="{{ modalValue }}">
        </div>
        <div class="modal-footer">
            <button class="btn btn-raised btn-outline-warning mr-2" (click)="confirmNew(tariffName.value)">{{ modalButtonText }}</button>
        </div>
    </div>
    <div *ngIf="modalType === 'copy'">
        <div class="modal-body">
            <select class="form-control" id="selectCopyId" #tariffToCopyId>
        <option *ngFor="let rate of rateList"  value="{{rate.id}}">{{rate.name}}</option>
      </select>
            <div class="modal-header">
                <h3 class="modal-title">Name of New Tariff</h3>
            </div>
            <input class="form-control" type="text" placeholder="{{ modalPlaceHolder }}" #tariffName id="tariffName_copy" name="tariffName" value="{{ modalValue }}">
        </div>
        <div class="modal-footer">
            <button class="btn btn-raised btn-outline-warning mr-2" id="buttonValue" (click)="confirmCopy(tariffName.value, tariffToCopyId.value)">{{ modalButtonText }}</button>
        </div>
    </div>
</ng-template>