import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationService {


  currentLocation: string;
  lastLocation: string;
  searchValue: string;
  goBack: boolean;
  lastApiUrl: string;
  constructor() { }
}
