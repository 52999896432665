import {Injectable} from '@angular/core';
import {HttpHandler, HttpRequest, HttpInterceptor} from '@angular/common/http';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/internal/operators';
import swal from 'sweetalert2';
import { OpenIdConnectService } from '../open-id-connect.service';
// import {ErrorService} from '../my-services/error.service';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor( private openIdConnectService: OpenIdConnectService ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError(error => {
        let errorMessage = '';
        console.log(error.error)
        if (error.status === 401) {
          this.openIdConnectService.triggerSignOut();
          console.log('logout');
        } else {
          if (error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Client-side error: ${error.error.message}`;
          } else {
            // backend error
            errorMessage = `Server-side error: ${error.status} ${error.message}`;
          }
          // aquí podrías agregar código que muestre el error en alguna parte fija de la pantalla.
          if (error.error === null) {
            swal('Error!', errorMessage.toString(), 'error');
          } else {
            console.log(error.message)
            swal('Error!', error.error, 'error');
          }

          return throwError(errorMessage);
        }
      })
    );
  }
}
