<form>
    <h4 class="form-section"></h4>
</form>
<form class="search-bar" style="display:inline-flex;" role="search">
    <div class="position-relative has-icon-right">
        <input type="text" class="form-control round" value="{{ searchValue }}" #search placeholder="Search" (keyup)="onSearch(search.value)">
        <div class="form-control-position">
            <i class="ft-search"></i>
        </div>

    </div>

</form>
<button _ngcontent-c27="" style="float:right;" class="btn btn-raised btn-outline-warning mr-2" type="button" (click)="onBack()">
  <span class="ft-corner-down-left"></span> Return
</button>
<table class="table mt-4 table-hover table-responsive-md table-sm table-striped">
    <thead>
        <tr>
            <th style="width: 110px;">Actions</th>
            <th (click)="orderColumn('Name')">Name <i *ngIf="orderBy=='Name' && reverse == false" class="fas fa-caret-up"></i><i *ngIf="orderBy=='Name' && reverse == true" class="fas fa-caret-down"></i></th>
            <th (click)="orderColumn('Supplier.Name')">Supplier <i *ngIf="orderBy=='Supplier.Name' && reverse == false" class="fas fa-caret-up"></i><i *ngIf="orderBy=='Supplier.Name' && reverse == true" class="fas fa-caret-down"></i></th>
            <th (click)="orderColumn('SupplierId')">Supplier Id <i *ngIf="orderBy=='SupplierId' && reverse == false" class="fas fa-caret-up"></i><i *ngIf="orderBy=='SupplierId' && reverse == true" class="fas fa-caret-down"></i></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let camper of campersList">
            <td>
                <a class="ft-edit list-action text-success" routerLink="camperRates/{{ camper.id }}" title="edit"></a>
            </td>
            <td>{{ camper.name }}</td>
            <td>{{ camper.supplierName }}</td>
            <td>{{ camper.supplierId }}</td>
        </tr>
    </tbody>
</table>
<ngb-pagination (pageChange)="onPager($event)" class="d-flex justify-content-center" [collectionSize]="totalPages" [(page)]="page" [maxSize]="5" [boundaryLinks]="true"> </ngb-pagination>
<button _ngcontent-c27="" class="btn btn-raised btn-outline-warning mr-2" type="button" (click)="onBack()">
  <span class="ft-corner-down-left"></span> Return
</button>