import { Component, OnInit } from '@angular/core';
import { OpenIdConnectService } from '../shared/open-id-connect.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-signin-oidc',
  templateUrl: './signin-oidc.component.html',
  styleUrls: ['./signin-oidc.component.scss']
})
export class SigninOidcComponent implements OnInit {

  constructor(private openIdConnectService: OpenIdConnectService,
    private router: Router) { }

  ngOnInit() {
    console.log('SigninOidcComponent init');
    this.openIdConnectService.userLoaded$.subscribe((userLoaded) => {
      console.log('SigninOidcComponent subscribe');
      if (userLoaded) {
        console.log('SigninOidcComponent user loaded');
        if(this.router.url.startsWith("/signin-oidc#"))
        {
          this.router.navigate(['./']);
        }
      } else {
        if (!environment.production) {
          console.log('An error happend: user wasn\'t loaded');
        }
      }
    })
    console.log('Go to handleCallback');
    this.openIdConnectService.handleCallback();
  }

}
