import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

    {
      path: '/bookings/dashboard', title: 'Home', icon: 'fas fa-home', class: '',
      badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
      path: '/users', title: 'Users', icon: 'far fa-user', class: '',
      badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
      path: '/partners', title: 'Partners', icon: 'far fa-handshake', class: '',
      badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
      path: '/', title: 'Campers', icon: 'fas fa-car-side', class: 'has-sub',
      badge: '', badgeClass: '', isExternalLink: false, submenu: [
       {
          path: '/campers', title: 'Campers', icon: 'fas fa-car-side', class: '',
          badge: '', badgeClass: '', isExternalLink: false, submenu: [],
       },
       {
         path: '/campers-properties', title: 'C. Properties', icon: 'fas fa-car-side2', class: '',
         badge: '', badgeClass: '', isExternalLink: false, submenu: []
        }
      ]
    },
    {
      path: '/', title: 'Suppliers', icon: 'fas fa-parachute-box', class: 'has-sub',
      badge: '', badgeClass: '', isExternalLink: false, submenu: [
        {
          path: '/suppliers', title: 'Supplier', icon: 'fas fa-parachute-box', class: '',
          badge: '', badgeClass: '', isExternalLink: false, submenu: [],
        },
        {
          path: '/supplier-offices', title: 'Supplier Offices', icon: 'fas fa-parachute-box2', class: '',
          badge: '', badgeClass: '', isExternalLink: false, submenu: [],
        },
        // {
        //   path: '/supplier-type', title: 'Supplier Type', icon: 'fas fa-parachute-box', class: '',
        //   badge: '', badgeClass: '', isExternalLink: false, submenu: [],
        // },
        // {
        //   path: '/import-type', title: 'Import type', icon: 'fas fa-parachute-box', class: '',
        //   badge: '', badgeClass: '', isExternalLink: false, submenu: [],
        // }
      ]
    },
    {
      path: '/', title: 'Reservations', icon: 'far fa-calendar-alt', class: 'has-sub',
      badge: '', badgeClass: '', isExternalLink: false, submenu: [
        { path: '/booking-engine', title: 'Booking Engine', icon: 'fas fa-calculator', class: '', badge: '',
        badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/bookings', title: 'Bookings', icon: 'far fa-address-book fa-2x', class: '', badge: '',
        badgeClass: '', isExternalLink: false, submenu: [] },
        /*{ path: '/bookings/dashboard', title: 'Dashboard', icon: 'far fa-chart-bar fa-2x', class: '', badge: '',
        badgeClass: '', isExternalLink: false, submenu: [] },*/
      ]
    },
    {
      path: '/', title: 'Destinations', icon: 'fas fa-globe', class: 'has-sub',
      badge: '', badgeClass: '', isExternalLink: false, submenu: [
        {
          path: '/destinations/continent', title: 'Continents', icon: 'fas fa-map-marker-alt', class: '',
          badge: '', badgeClass: '', isExternalLink: false, submenu: [],
        },
        {
          path: '/destinations/country', title: 'Countries', icon: 'fas fa-map-marker-alt', class: '',
          badge: '', badgeClass: '', isExternalLink: false, submenu: [],
        },
        {
          path: '/destinations/region', title: 'Regions', icon: 'fas fa-map-marker-alt', class: '',
          badge: '', badgeClass: '', isExternalLink: false, submenu: [],
        },
        {
          path: '/destinations/city', title: 'Cities', icon: 'fas fa-map-marker-alt', class: '',
          badge: '', badgeClass: '', isExternalLink: false, submenu: [],
        }
      ]
    },
    {
      path: '/exchange-rates', title: 'Exchange Rates', icon: 'fas fa-exchange-alt', class: '',
      badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
      path: '', title: 'Rates', icon: 'fas fa-greater-than', class: 'has-sub', badge: '',
      badgeClass: '', isExternalLink: false, submenu: [
      { path: '/rates', title: 'Camper Rates', icon: 'fas fa-clipboard-list', class: '',
      badge: '', badgeClass: '', isExternalLink: false,
      submenu: []},
      { path: '/matrix', title: 'Flex Matrix', icon: 'fas fa-vector-square', class: '', badge: '',
      badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/import-rates', title: 'Import Rates', icon: 'fas fa-file-import', class: '', badge: '',
      badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/drop-off', title: 'Drop Off Matrix', icon: 'fas fa-globe', class: '', badge: '',
      badgeClass: '', isExternalLink: false, submenu: [] },
      /*{ path: '/price-calculator', title: 'Price Calculator', icon: 'fas fa-calculator', class: '', badge: '',
      badgeClass: '', isExternalLink: false, submenu: [] },*/
      { path: '/discounts', title: 'Discounts', icon: 'fas fa-percent', class: '', badge: '',
      badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/discount-codes', title: 'Discount Code', icon: 'fas fa-percent', class: '', badge: '',
        badgeClass: '', isExternalLink: false, submenu: [] }
      ],
      
    },
    {
      path: '/reports', title: 'Reports', icon: 'far fa-file-alt', class: '',
      badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    // {
    //     path: '/content-layout', title: 'Content Layout', icon: 'ft-square', class: '',
    //     badge: '', badgeClass: '', isExternalLink: false, submenu: []
    // },
    // {
    //     path: '', title: 'Menu Levels', icon: 'ft-align-left', class: 'has-sub', badge: '1',
    //      badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
    //     submenu: [
    //         { path: 'javascript:;', title: 'Second Level', icon: '', class: '', badge: '',
    //          badgeClass: '', isExternalLink: true, submenu: [] },
    //         {
    //             path: '', title: 'Second Level Child', icon: '', class: 'has-sub',
    //              badge: '', badgeClass: '', isExternalLink: false,
    //             submenu: [
    //                 { path: 'javascript:;', title: 'Third Level 1.1', icon: '', class: '',
    //                  badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
    //                 { path: 'javascript:;', title: 'Third Level 1.2', icon: '', class: '',
    //                  badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
    //             ]
    //         },
    //     ]
    // },
    // {
    //     path: '/changelog', title: 'ChangeLog', icon: 'ft-file', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    // },
    // { path: 'https://pixinvent.com/apex-angular-4-bootstrap-admin-template/documentation',
    //  title: 'Documentation', icon: 'ft-folder', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },
    // { path: 'https://pixinvent.ticksy.com/', title: 'Support', icon: 'ft-life-buoy',
    //  class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: [] },

];
