import { Component, OnInit, TemplateRef, ViewChild, EventEmitter, Output, QueryList } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import swal from 'sweetalert2';
import { Rate } from '../shared/rates.model';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbTabChangeEvent, NgbTabset, NgbTab } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { DateAdapter } from '../shared/util/DateAdapter';
import { Office } from '../shared/office.model';
import { BreadcrumbsService } from 'ng6-breadcrumbs';
import { ManualRatesService } from '../shared/manual-rates.service';
import { SeasonDate, Season, MpList, ManualPriceV2, CamperList } from '../shared/modelPrice.model';
import { RatesService } from '../shared/rates.service';
import { LocationService } from 'app/shared/services/location.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-campers-manual-rates',
  templateUrl: './campers-manual-rates.component.html',
  styleUrls: ['./campers-manual-rates.component.scss'],
  providers: [NgbTabset]
})
export class CampersManualRatesComponent implements OnInit {
  @Output() emitEvent = new EventEmitter<string>();
  componentName = 'CamperRates';
  _camperId: string;
  _supplierId: number;
  modalRef: NgbModalRef;
  rateList: Array<Rate>;

  seasonDateList: Array<SeasonDate>;
  seasonsList: Array<Season>;
  modalTitle: string;
  modalType: string;
  modalPlaceHolder: string;
  modalButtonText: string;
  modalValue: string;
  modalOpen: boolean;
  newTariffTabId: string;
  activeTabId: string;
  activeTabName: string;
  tEvent: NgbTabset;
  nextTabId: string;
  isEnabled: boolean;
  camperName: string;
  supplierName: string;
  officeList: Array<Office>;
  closeResult: string;
  mpList: MpList;
  mpListV2: Array<MpList>;
  manualPriceList: Array<ManualPriceV2>;
  mpSeasonList: Array<Season>;
  mpCamperList: Array<CamperList>;
  @ViewChild('tabs')
  private tabs: NgbTabset;

  constructor(
    private modalService: NgbModal,
    // private ratesService: RatesService,
    private manualRatesService: ManualRatesService,
    private ratesService: RatesService,
    private _route: ActivatedRoute,
    private _router: Router,
    private tabSet: NgbTabset,
    private dateAdapter: DateAdapter,
    private breadcrumbsService: BreadcrumbsService,
    private locationService: LocationService,
    private location: Location
    // private toastr: ToastrService
    ) {}

  ngOnInit() {
    this._supplierId = Number.parseInt(this._route.snapshot.paramMap.get('id'), 10);
    this.breadcrumbsService.store([{label: 'Rates' , url: '/rates', params: []},
    {label: 'Supplier List' , url: '/rates', params: []}, {label: 'Camper Rates Manual Editior' , url: '/', params: []}])
    this.modalOpen = false;
    this.modalValue = 'Manual Tariff';
    this.tabSet.tabs = new QueryList<NgbTab>();
    this.rateList = new Array<Rate>();
    this.manualPriceList = new Array<ManualPriceV2>();
    this.seasonDateList = new Array<SeasonDate>();
    this.manualRatesService.getSupplier(this._supplierId).subscribe(supplier => {
      this.emitEvent.emit(supplier.name);
    });
    this.getOfficeList();
    this.getTariffList(this._supplierId);
    this.getSeasonList();
  }

  select(tabId: string) {
    console.log('Select - ', tabId);
  }
  openModal(template: TemplateRef<any>) {
    this.modalOpen = true;
    this.modalRef = this.modalService.open(template);
    this.modalRef.result.then(result => {
    }, (reason) => {
      this.closeResult = 'Dismissed';
    });
  }

  decline(): void {
    this.modalRef.close();
    };

  newSelect(t: NgbTabset) {
    t.select(t.activeId);
  }
  beforeChange($event: NgbTabChangeEvent, template: TemplateRef<any>, tabs: NgbTabset) {
    this.tabSet = tabs;
    const tabIdName = $event.nextId;
    this.modalOpen = true;
    this.selectModal(tabIdName, template);
    $event.nextId = this.nextTabId;
    this.activeTabId = tabIdName;
    const tabIdRow = this.rateList.indexOf(
      this.rateList.find(function (rate) {
        return rate.id.toString() === tabIdName;
      }));
    this.activeTabName = this.rateList[tabIdRow].name;
  };

  selectModal(tabIdName: string, template: TemplateRef<any>) {
    if (tabIdName === 'new') {
      console.log('new')
      this.modalTitle = 'New Tariff';
      this.modalPlaceHolder = 'Name of New Tariff';
      this.modalType = 'new';
      this.modalButtonText = 'Submit!';
      this.openModal(template);
    } else if (tabIdName === 'copy') {
      console.log('copy')
      this.modalTitle = 'Copy Tariff From';
      this.modalType = 'copy';
      this.modalButtonText = 'Submit!'
      this.openModal(template);
    } else {
      this.getManualPriceList(tabIdName);
      this.getSeasonDate(tabIdName);
    }
  }
  onBack(): void {
    if (this.ratesService.sameModule) {
      this.ratesService.sameModule = false;
      this.ratesService.goBack = true;
    } else {
      this.locationService.goBack = true;
    }
    this.location.back();
  }
  // OFFICE SECTION --------------------------------------------------------------------------------------------------

  getOfficeList() {
    this.manualRatesService.getOfficeList(this._supplierId).subscribe(data => {
      this.officeList = data;
      console.log('Office List -> ', data);
    })
  }

  updateManualFarefOffice(value: string, fareOfficeId: string, officeId: number, index: number) {
    console.log('tariff id -> ', this.officeList[index]);
    console.log('value ->', value); // Fare Id
    console.log('FareOffice id -> ', fareOfficeId); // Row DB Id
    console.log('Office Id -> ', officeId); // Office Id
    this.manualRatesService.updateManualFareOffice(fareOfficeId, value, officeId).subscribe(resp => {
      // console.log(resp);
      // this.officeList[index].fareOfficeId = resp.Id.toString();
      // this.toastr.success(this.officeList[index].name + ' Tariff Updated', 'Success!');
    });
  }

  // END OFFICE SECTION ----------------------------------------------------------------------------------------------
  // MANUAL PRICE SECTION ----------------------------------------------------------------------------------------------
  getManualPriceList(idTariff: string) {
    this.manualRatesService.getManualPrice(idTariff).subscribe( data => {
      if (data !== null) {
        this.mpListV2 = new Array<MpList>();
        this.mpListV2.push(data);
        this.mpList = data;
        this.mpSeasonList = this.mpList.seasonsList;
        this.mpCamperList = this.mpList.camperList;
        this.manualPriceList = this.mpList.manualPriceList;
        console.log(this.mpSeasonList);
        console.log(this.mpCamperList);
        console.log(this.manualPriceList);
      }
    })
  }
  updateManualPriceValue(value: number, id: number) {
    console.log(value + ' ' + id);
    this.manualRatesService.updateManualPrice(value, id).subscribe(data => {

    })
  }
  // END MANUAL PRICE SECTION ----------------------------------------------------------------------------------------------
  // TARIFF SECTION ------------------------------------------------------------------------------------------------------

  onChangeAllInclusive(event: any){
    this.rateList.find(x => x.id == this.activeTabId).allInclusive =  event.target.checked;
    this.manualRatesService.updateAllInclusive(this.activeTabId, event.target.checked).subscribe(data =>{
      console.log("allInclusive: " + event.target.checked);
    });
  }
  confirmNew(name: string): void {
    this.modalRef.close();
    this.modalOpen = false;
    this.newTariff(name);
  }
  confirmCopy(name: string, tarifftoCopyId): void {
    // this.modalRef.hide();
    this.modalOpen = false;
    this.copyTariff(tarifftoCopyId, name);
  }
  getTariffList(idSupplier: number) {
    console.log('get Tariffs');
    this.manualRatesService.getFaresBySupplier(idSupplier).subscribe(data => {
      if ( data.length !== 0) {
        this.rateList = data;
        this.activeTabId = this.rateList[0].id;
        this.activeTabName = this.rateList[0].name;
        this.isEnabled = true;
         this.getManualPriceList(this.rateList[0].id);
         this.getSeasonDate(this.rateList[0].id);
      } else {
        this.isEnabled = false;
      }
    });
  }
  getTariffListWithLast(idSupplier: number, lastId: string, lastName: string) {
    console.log('get Tariffs');
    this.manualRatesService.getFaresBySupplier(idSupplier).subscribe(data => {
      if ( data.length !== 0) {
        this.rateList = data;
        this.activeTabId = lastId;
        this.activeTabName = lastName;
        this.isEnabled = true;
         this.getManualPriceList(lastId);
         this.getSeasonDate(lastId);
      } else {
        this.isEnabled = false;
      }
    });
  }
  newTariff(name: string) {
    // this.modalRef.hide();
    console.log(this.tabSet.activeId);
    console.log('esto - ', this.tabSet);
    this.manualRatesService.newTariff(name, this._supplierId).subscribe(data => {
      this.manualRatesService.newManualPrice(data.id, this._supplierId).subscribe(dataManualPrice => {
        console.log('DataManualPrice -> ', dataManualPrice);
        this.newTariffTabId = data.id;
        console.log('TabSet -> ', this.tabSet);
        // swal('Success!', name + ' Created!', 'success')
        // this.toastr.success(name + ' Created', 'Success!');
        this.getTariffListWithLast(this._supplierId, data.id, data.name);
      });
    });
  }
  copyTariff(tarifftoCopyId: string, name: string) {
    this.modalRef.close();
    this.manualRatesService.copyTariff(name, this._supplierId, tarifftoCopyId).subscribe(data => {
      // console.log(data);
      // this.toastr.success(name + ' Created', 'Success!');
      this.getTariffList(this._supplierId);
      setTimeout(() => {
        this.tabSet.select(data.id);
      }, 500);
    })
    console.log('tarifftoCopyId -> ', tarifftoCopyId + ' ' + name );
  }
  deleteTariff(id: string, name: string) {
    this.manualRatesService.deleteTariff(id).subscribe(data => {
      swal('Deleted!', name + ' deleted!', 'success')
      this.getTariffList(this._supplierId);
    })
  }
  addTariff(template: TemplateRef<any>) {
    console.log('new')
    this.modalTitle = 'New Tariff';
    this.modalPlaceHolder = 'Name of New Tariff';
    this.modalType = 'new';
    this.modalButtonText = 'Submit!';
    this.openModal(template);
  }
  copyNewTariff(template: TemplateRef<any>) {
    console.log('copy')
    this.modalTitle = 'Copy Tariff From';
    this.modalType = 'copy';
    this.modalButtonText = 'Submit!'
    this.openModal(template);
  }
  confirmDeleteTariff() {
    // if (this.activeTabName !== undefined ) {
      swal({
        title: 'Are you sure?',
        text: 'You are going to delete ' + this.activeTabName ,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0CC27E',
        cancelButtonColor: '#FF586B',
        confirmButtonText: 'Delete Tariff',
        cancelButtonText: 'No, cancel'
    }).then((value) => {
      console.log(value);
      if (!value.dismiss) {
        this.deleteTariff(this.activeTabId, this.activeTabName);
      }
    }).catch(swal.noop);
    // }
  }
  // END TARIFF SECTION ---------------------------------------------------------------------------------------------------

  // SEASON DATE SECTION --------------------------------------------------------------------------------------------------
  getSeasonDate(idTariff: string) {
    this.manualRatesService.getSeasonDate(idTariff).subscribe(data => {
      this.seasonDateList = data;
      this.seasonDateList.forEach(seasonDate => {
        seasonDate.fromBackUp = seasonDate.from,
        seasonDate.toBackUp = seasonDate.to,
        seasonDate.season.nameBackUp = seasonDate.season.name,
        seasonDate.season.idBackUp = seasonDate.season.id
      })
    })
  }
  getSeasonList() {
    this.manualRatesService.getSeasons().subscribe( data => {
      this.seasonsList = data;
    })
  }
  editSeasonDate(index: number) {
    this.seasonDateList[index].edit = true;
  }
  cancelSeasonDate(index: number) {
    if (this.seasonDateList[index].new === true) {
      console.log('delete', index);
      console.log(this.seasonDateList[index]);
      this.seasonDateList.splice(index, 1);
     } else {
      this.seasonDateList[index].edit = !this.seasonDateList[index].edit;
      this.seasonDateList[index].from = this.seasonDateList[index].fromBackUp;
      this.seasonDateList[index].to = this.seasonDateList[index].toBackUp;
      this.seasonDateList[index].season.id = this.seasonDateList[index].season.idBackUp;
      this.seasonDateList[index].season.name = this.seasonDateList[index].season.nameBackUp;
     }

  }
  updateSeasonDateName(seasonId: number, index: number) {
    console.log(this.seasonsList, seasonId);
    const seasonNameIndex = this.seasonsList.indexOf(
      this.seasonsList.find(function (season) {
        return season.id.toString() === seasonId.toString();
      }));
    this.seasonDateList[index].season.name = this.seasonsList[seasonNameIndex].name;
    this.seasonDateList[index].seasonName = this.seasonsList[seasonNameIndex].name;
    this.seasonDateList[index].seasonId = seasonId;
    console.log(this.seasonDateList[index]);
  }
  updateSeasonDateList(idRow: number) {
    if (this.seasonDateList[idRow].new) {
      const newSeasonDate = this.seasonDateList[idRow];
      this.manualRatesService.newSeasonDate(newSeasonDate).subscribe( data => {
        // this.seasonDateList[idRow] = data;
        this.getSeasonDate(this.activeTabId);
      } );
      console.log('works new -> ', this.seasonDateList[idRow]);

    } else {
      console.log('works update -> ', this.seasonDateList[idRow]);
      const seasonDateToUpdate = this.seasonDateList[idRow];
      this.seasonDateList[idRow].edit = !this.seasonDateList[idRow].edit;
      this.manualRatesService.updateSeasondate(seasonDateToUpdate.id, seasonDateToUpdate).subscribe(data => {
      })
    }
  }
  updateManualDate (idRow: number, date: string, myProp: string) {
    console.log('date ->' + date);
    const newDate = new Date();
    const dateArray = date.split('/');
    const day = dateArray[0];
    const month = dateArray[1];
    let year = dateArray[2];
    if (year.length < 3) {
      year = '20' + year;
    }
    console.log(year + '-' + month + '-' + day);
    // const newDate = this.dateAdapter.toModel(date);
    this.seasonDateList[idRow][myProp] = year + '-' + month + '-' + day;
    // console.log('row -> ', this.basePriceList[idRow][myProp]);
  }
  updateSeasonDateRowDate(idRow: number, date: NgbDate, myProp: string) {
    console.log('date ->' + date);
    const newDate = this.dateAdapter.toModel(date);
    this.seasonDateList[idRow][myProp] = date.year + '-' + date.month + '-' + date.day;
    console.log('row -> ', this.seasonDateList[idRow][myProp]);
  }
  confirmDeleteSeasonDate(id: string) {
    swal({
        title: 'Are you sure?',
        text: 'You are going to delete a Season Date',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0CC27E',
        cancelButtonColor: '#FF586B',
        confirmButtonText: 'Delete Season Date',
        cancelButtonText: 'No, cancel'
    }).then((value) => {
      console.log(value);
      if (!value.dismiss) {
        this.deleteSeasonDate(id);
      }
    }).catch(swal.noop);
}
  deleteSeasonDate(id: string) {
    this.manualRatesService.deleteSeasonDate(id).subscribe(data => {
      swal('Deleted!', 'Season Date Deleted!', 'success')
      this.getSeasonDate(this.activeTabId);
    });
  }
  addSeasonDate() {
    const newSeasonDate = new SeasonDate();
    const season = new Season();
    season.id = 0;
    season.name = '';
    newSeasonDate.new = true;
    newSeasonDate.edit = true;
    newSeasonDate.manualFareId = Number.parseInt(this.activeTabId, 10);
    // newSeasonDate.season.id = 0;
    newSeasonDate.season = season;
    this.seasonDateList.push(newSeasonDate);
  }
  // END SEASON DATE SECTION ----------------------------------------------------------------------------------------------
}

