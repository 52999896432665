import { Routes, RouterModule } from '@angular/router';

// Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
  {
    path: 'users',
    loadChildren: './users/users.module#UsersModule',
    data: { breadcrumb: 'Users' }
  },
  {
    path: 'partners',
    loadChildren: './partners/partners.module#PartnersModule',
    data: { breadcrumb: 'Partners' }
  },
  {
    path: 'campers',
    loadChildren: './campers/campers.module#CampersModule',
    data: { breadcrumb: 'Campers' }
  },
  {
    path: 'campers-properties',
    loadChildren: './campers-properties/campers-properties.module#CampersPropertiesModule',
    data: { breadcrumb: 'Campers Properties' }
  },
  {
    path: 'suppliers',
    loadChildren: './suppliers/suppliers.module#SuppliersModule',
    data: { breadcrumb: 'Suppliers' }
  },
  {
    path: 'supplier-offices',
    loadChildren: './supplier-offices/supplier-offices.module#SupplierOfficesModule',
    data: { breadcrumb: 'Suppliers' }
  },
  {
    path: 'reservations',
    loadChildren: './reservations/reservations.module#ReservationsModule',
    data: { breadcrumb: 'Reservations' }
  },
  {
    path: 'full-layout',
    loadChildren: './pages/full-layout-page/full-pages.module#FullPagesModule',
    data: { breadcrumb: 'Home' }
  },
  {
    path: 'exchange-rates',
    loadChildren: './exchange-rates/exchange-rates.module#ExchangeRatesModule',
    data: { breadcrumb: 'Exchange Rate' }
  },
  {
    path: 'rates',
    loadChildren: './rates/rates.module#RatesModule',
    data: { breadcrumb: 'Rates' }
  },
  {
    path: 'discount-codes',
    loadChildren: './discount-codes/discount-codes.module#DiscountCodesModule',
    data: { breadcrumb: 'Discount Codes' }
  },
  {
    path: 'matrix',
    loadChildren: './matrix/matrix.module#MatrixModule',
    data: { breadcrumb: 'Matrix' }
  },
  {
    path: 'import-rates',
    loadChildren: './import-rates/import-rates.module#ImportRatesModule',
    data: { breadcrumb: 'Import Rates' }
  },
  {
    path: 'drop-off',
    loadChildren: './drop-off/drop-off.module#DropOffModule',
    data: { breadcrumb: 'Drop Off' }
  },
  /*{
    path: 'price-calculator',
    loadChildren: './price-calculator/price-calculator.module#PriceCalculatorModule',
    data: { breadcrumb: 'Price Calculator' }
  },*/
  {
    path: 'booking-engine',
    loadChildren: './booking-engine/booking-engine.module#BookingEngineModule',
    data: { breadcrumb: 'Booking Engine' }
  },
  {
    path: 'bookings',
    loadChildren: './bookings/bookings.module#BookingsModule',
    data: { breadcrumb: 'Bookings' }
  },
  {
    path: 'reports',
    loadChildren: './reports/reports.module#ReportsModule',
    data: { breadcrumb: 'Reports' }
  },
  {
    path: 'discounts',
    loadChildren: './discounts/discounts.module#DiscountsModule',
    data: { breadcrumb: 'Discounts' }
  },
  {
    path: 'import-type',
    loadChildren: './import-type/import-type.module#ImportTypeModule',
    data: { breadcrumb: 'Import Type' }
  },
  {
    path: 'supplier-type',
    loadChildren: './supplier-type/supplier-type.module#SupplierTypeModule',
    data: { breadcrumb: 'Supplier Type' }
  },
  {
    path: 'destinations',
    loadChildren: './destinations/destinations.module#DestinationsModule',
    data: { breadcrumb: 'Destinations' }
  },
  {
    path: 'continent',
    loadChildren: './destinations/destinations.module#DestinationsModule',
    data: { breadcrumb: 'Destinations' }
  },
  {
    path: 'country',
    loadChildren: './destinations/destinations.module#DestinationsModule',
    data: { breadcrumb: 'Country' }
  },
  {
    path: 'region',
    loadChildren: './destinations/destinations.module#DestinationsModule',
    data: { breadcrumb: 'Region' }
  },
  {
    path: 'city',
    loadChildren: './destinations/destinations.module#DestinationsModule',
    data: { breadcrumb: 'City' }
  }

];
