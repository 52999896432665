import { Rate } from './rates.model';

export class Office {
  id: string;
  tariffId: string;
  name: string;
  fare: Rate;
  fareOfficeId: string;
  manualFareId: number;
}

export class FareOffice {
  id: string;
  FareId: string;
  supplierOfficesId: number;
}
export class ManualOffice {
  Id: number;
  ManualFareId: number;
  supplierOfficesId: number;
}
