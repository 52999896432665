import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';

import { Full_ROUTES } from './shared/routes/full-layout.routes';
import { CONTENT_ROUTES } from './shared/routes/content-layout.routes';

import { SigninOidcComponent } from './signin-oidc/signin-oidc.component';

import {RequiredAuthenticatedUserRouteGuardService} from './shared/required-authenticated-user-route-guard.service';
import { RedirectSilentRenewComponent } from './redirect-silent-renew/redirect-silent-renew.component';

const appRoutes: Routes = [
  { path: '', redirectTo: 'bookings/dashboard', pathMatch: 'full', canActivate: [RequiredAuthenticatedUserRouteGuardService]},
  { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [RequiredAuthenticatedUserRouteGuardService] },
  { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES, canActivate: [RequiredAuthenticatedUserRouteGuardService] },
  { path: 'signin-oidc', component: SigninOidcComponent},
  { path: 'redirect-silentrenew', component: RedirectSilentRenewComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
