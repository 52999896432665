import { NgModule } from '@angular/core';
 import { CommonModule } from '@angular/common';
 import { RouterModule } from '@angular/router';

 import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
// import { Ng5BreadcrumbModule, BreadcrumbComponent, BreadcrumbService } from 'ng5-breadcrumb';
import {BreadcrumbsModule} from 'ng6-breadcrumbs';


@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ToggleFullscreenDirective,
        // Ng5BreadcrumbModule,
        // BreadcrumbComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        // Ng5BreadcrumbModule

    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ToggleFullscreenDirective
    ],
    // providers: [BreadcrumbService]
})
export class SharedModule { }
