export class ManualPrice {
  id: string;
  camper: string;
  off: number;
  low: number;
  sho: number;
  mid: number;
  high: number;
  peak: number;
  offBackUp: number;
  lowBackUp: number;
  shoBackUp: number;
  midBackUp: number;
  highBackUp: number;
  peakBackUp: number;
  edit = false;
  new = false;
}
export class ManualPriceToCreate {
  id: string;
  camper: string;
  off: number;
  low: number;
  sho: number;
  mid: number;
  high: number;
  peak: number;
}
export class ManualPriceToUpdate {
  id: number;
  value: number;
}
export class SeasonDate {
  id: number;
  from: Date;
  to: Date;
  seasonName: string;
  seasonId: number;
  fromBackUp: Date;
  toBackUp: Date;
  seasonNameBackUp: string;
  seasonIdBackUp: number;
  edit = false;
  new = false;
  manualFareId: number;
  season: Season;
}
export class Season {
  id: number;
  name: string;
  nameBackUp: string;
  idBackUp: number;
}
export class SeasonDateToUpdate {
  id: number;
  from: Date;
  to: Date;
  seasonName: string;
  seasonId: number;
  edit = false;
  new = false;
  manualFareId: number;
}

export class MpList {
  manualPriceList: Array<ManualPriceV2>;
  camperList: Array<CamperList>;
  seasonsList: Array<Season>;
}
export class ManualPriceV2 {
  id: number;
  price: number;
  manualFareId: number;
  season: Season;
  seasonId: number;
  camperId: number;
  camperNameTmp: string;
}
export class CamperList {
  id: number;
  name: string;
}
