import { Component, OnInit, ViewChild, ViewChildren, AfterViewInit, QueryList} from '@angular/core';
import { CampersRatesComponent } from './campers-rates/campers-rates.component';
import { CampersListComponent } from './campers-list/campers-list.component';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromEvent';
@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.scss'],

})
export class RatesComponent implements OnInit {

  title: string;
  // @ViewChild(CampersRatesComponent) camperRates: CampersRatesComponent;
  @ViewChildren('CampersRatesComponent') camperRatesName: QueryList<CampersRatesComponent>;

  constructor() {
   }

  ngOnInit() {
    this.title = 'Camper Rates';
    // this.title = this.camperList.camperName;
    // console.log('Camper Name ->', this.camperList.camperName);
  }
  onActivate(camper: CampersRatesComponent) {
    if (camper.componentName !== 'CamperRates') {
      this.title = 'Camper Rates';
    } else {
      camper.emitEvent.subscribe(name => {
        this.title = name;
        console.log(name);
      });
    }

  }
}
