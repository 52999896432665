
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { FullLayoutComponent } from './layouts/full/full-layout.component';

import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';

// import * as $ from 'jquery';
import { UsersService } from './users/shared/users.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OpenIdConnectService } from './shared/open-id-connect.service';
import { SigninOidcComponent } from './signin-oidc/signin-oidc.component';
import { RedirectSilentRenewComponent } from './redirect-silent-renew/redirect-silent-renew.component';
import { RequiredAuthenticatedUserRouteGuardService } from './shared/required-authenticated-user-route-guard.service';
import 'automapper-ts/dist/automapper';
import { AddAuthorizationHeaderInterceptor } from './shared/add-authorization-header-interceptor';
import { ToastrModule } from 'ngx-toastr';
import { RatesModule } from './rates/rates.module';
import { MatrixComponent } from './matrix/matrix.component';
import { ImportRatesComponent } from './import-rates/import-rates.component';
import { DropOffComponent } from './drop-off/drop-off.component';
// import {Ng5BreadcrumbModule, BreadcrumbService} from 'ng5-breadcrumb';
// import { McBreadcrumbsModule } from 'ngx-breadcrumbs';
import {BreadcrumbsModule} from 'ng6-breadcrumbs';
import { HttpErrorInterceptor } from './shared/services/httpErrorInterceptor';
import { LocationService } from './shared/services/location.service';
import { DragulaModule } from 'ng2-dragula';

@NgModule({
    declarations: [
        AppComponent,
        FullLayoutComponent,
        ContentLayoutComponent,
        SigninOidcComponent,
        RedirectSilentRenewComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        // Ng5BreadcrumbModule.forRoot(),
        BreadcrumbsModule,
        SharedModule,
        HttpClientModule,
        RatesModule,
        ToastrModule.forRoot(),
        NgbModule.forRoot(),
        DragulaModule.forRoot()

    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AddAuthorizationHeaderInterceptor,
            multi: true
          },
          {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
          },
        AuthService,
        AuthGuard,
        UsersService,
        NgbActiveModal,
        OpenIdConnectService,
        RequiredAuthenticatedUserRouteGuardService,
        LocationService
        // BreadcrumbService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor() {}
}
