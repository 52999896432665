import { Pipe, PipeTransform } from '@angular/core';
import { ManualPriceV2 } from './modelPrice.model'
@Pipe({
  name: 'filterPrice'
})
export class FilterPrice implements PipeTransform  {
  transform(manualPrice: Array<ManualPriceV2>, idCamper: number, seasonId: number): any {
    const result = manualPrice.filter(value => value.camperId.toString() === idCamper.toString()
    && value.seasonId.toString() === seasonId.toString())[0].price;
    return result;
  }
}
@Pipe({
  name: 'filterId'
})
export class FilterId implements PipeTransform  {
  transform(manualPrice: Array<ManualPriceV2>, idCamper: number, seasonId: number): any {
    const result = manualPrice.filter(value => value.camperId.toString() === idCamper.toString()
    && value.seasonId.toString() === seasonId.toString())[0].id;
    return result;
  }
}
@Pipe({
  name: 'availability'
})
export class AvailabilityPipe implements PipeTransform  {
  transform(availability: string): string {
    let valueToReturn: string;
    if (availability !== undefined && availability !== null) {
      if (availability === 'FS') {
        valueToReturn = 'Available';
      } else if (availability === 'RQ') {
        valueToReturn = 'On Request';
      }
    } else {
      valueToReturn = 'On Request';
    }
    return valueToReturn;
  }
}
