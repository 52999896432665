import { Component, OnInit, OnDestroy } from '@angular/core';
import { RatesService } from '../shared/rates.service';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { Camper } from '../shared/campers.model';
import { BreadcrumbsService } from 'ng6-breadcrumbs';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { LocationService } from 'app/shared/services/location.service';

@Component({
  selector: 'app-campers-list',
  templateUrl: './campers-list.component.html',
  styleUrls: ['./campers-list.component.scss'],
  providers: [NgbPaginationConfig]

})
export class CampersListComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();
  orderBy = 'Id';
  reverse = false;
  campersList: Array<Camper>;
  _supplierId: string;
  totalCount: number;
  totalPages: number;
  pageSize = 1;
  page = 1;
  currentPage = 1;
  isSearched = false;
  searchValue = '';
  camperName: string;
  isManual: string;
  constructor(
    private ratesService: RatesService,
    private config: NgbPaginationConfig,
    private _route: ActivatedRoute,
    private _router: Router,
    private breadcrumbsService: BreadcrumbsService,
    private location: Location,
    private locationService: LocationService
    ) {config.boundaryLinks = true; }

  ngOnInit() {
    this.breadcrumbsService.store([{label: 'Rates' , url: '/rates', params: []},
    {label: 'Supplier List' , url: '/rates', params: []}, {label: 'Campers List' , url: '/', params: []}])
    this._supplierId = this._route.snapshot.paramMap.get('id');
    console.log(this._supplierId);
    this.campersList = new Array<Camper>();
    // If the last page is in the same module and goBack is true
    if (this.ratesService.goBack && this.ratesService.sameModule) {
      this.searchValue = this.ratesService.searchValue;
      console.log('last');
      this.getLastUrl();
    } else {
      this.searchValue = '';
      this.getCampersListBySupplier(this._supplierId);
    }
    this.ratesService.goBack = false;
    // this.ratesService.readOldBasePrices().subscribe(data => {
    //   console.log(data);
    // })
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  // public emitName(name: string): string {
  //   console.log('Emit ->', name);
  //   this.emitEvent.emit(name);
  //   console.log(this.emitEvent);
  //   this.camperName = name;
  //   return name;
  // }
  getLastUrl() {
    this.subscriptions.add(
      this.ratesService.getLastUrl().subscribe(resp => {
        this.campersList = resp.body;
        const xPagination = JSON.parse(resp.headers.get('x-pagination'));
        this.pageSize = xPagination.pageSize;
        this.totalPages = (xPagination.totalPages * 10) / 1;
        this.page = xPagination.currentPage;
        this.ratesService.lastUrl = resp.url;
      })
    )
  }
  getCampersListBySupplier(id: string) {
    this.subscriptions.add(
      this.ratesService.getCampersBySupplier(id).subscribe(data => {
        this.campersList = data;
      })
    )
  }
  getCampersList() {
    this.subscriptions.add(
      this.ratesService.getCampersV1().subscribe(resp => {
        const xPagination = JSON.parse(resp.headers.get('x-pagination'));
        // console.log(xPagination);
        this.pageSize = xPagination.pageSize;
        this.totalPages = (xPagination.totalPages * 10) / 2;
        this.getCampersListByPage(this.page);
      }, error => { console.log(); } )
    )
  }
  getCampersListByPage(pageToGet) {
    this.subscriptions.add(
      this.ratesService.getCampersV1ByPage(this.pageSize * 2, pageToGet).subscribe(data => {
        this.campersList = data.body;
        this.ratesService.lastUrl = data.url;
      }, error => { console.log(error); } )
    )
  }
  onPager(event: number) {
    if ( this.isSearched ) {
      this.getCampersListByPagedSearch(this.searchValue, event);
    } else {
      this.getCampersListByPage(event);
    }
  }
  getCampersListByPagedSearch(value: string, pageToGet: number) {
    this.subscriptions.add(
      this.ratesService.getCampersV1ByPagedSearch(value, this.pageSize * 2, pageToGet).subscribe( resp => {
        this.campersList = (resp.body);
        console.log(resp.headers);
        const xPagination = JSON.parse(resp.headers.get('x-pagination'));
        this.pageSize = xPagination.pageSize;
        this.totalPages = xPagination.totalPages * 10;
        this.ratesService.lastUrl = resp.url;
      })
    )
  }
  onSearch(value: string) {
    this.ratesService.searchValue = value;
    if ( value.length > 3 ) {
      this.searchValue = value;
      this.isSearched = true;
      this.getCampersBySearch(value);
    }
    if (value.length === 3 && this.isSearched) {
      this.getCampersList();
      this.isSearched = false;
    }
    if (this.campersList.length === 0 && value.length < 3) {
      this.getCampersList();
    }
    if (value.length === 0 && this.campersList.length !== 0) {
      if (this.isSearched) {
        this.getCampersList();
        this.isSearched = false;
      }
    }
  }
  getCampersBySearch(value: string) {
    this.subscriptions.add(
      this.ratesService.getCampersV1BySearch(value).subscribe( resp => {
        this.campersList = (resp.body);
        const xPagination = JSON.parse(resp.headers.get('x-pagination'));
        this.pageSize = xPagination.pageSize;
        this.totalPages = xPagination.totalPages * 10;
        this.ratesService.lastUrl = resp.url;
      })
    )
  }
  onBack(): void {
    if (this.ratesService.sameModule) {
      this.ratesService.sameModule = false;
      this.ratesService.goBack = true;
    } else {
      this.locationService.goBack = true;
    }
    this.location.back();
  }
  orderColumn(val: string) {
    if(val == this.orderBy) this.reverse = !this.reverse;
    this.orderBy = val;
    if ( this.isSearched ) {
      this.getCampersBySearch(this.searchValue);
    } else {
      this.getCampersList();
    }
  }
}
