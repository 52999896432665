import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';

// import { User } from '../../shared/models/user.model';
import { UserForCreation } from './user-for-creation.model';
import { UserForUpdate } from './user-for-update.model';
import { User, CounterUsers } from './user.model';

import { Observable, pipe, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseService } from '../../shared/services/base.service';

import { UserRoles } from '../shared/user-roles.model';


@Injectable()
export class UsersService extends BaseService {
  users: User[];
  config = new Array;
  lastUrl: string;
  searchValue: string;
  goBack: boolean;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    observe: 'response' as 'response'
  };

  constructor(public http: HttpClient) {
    super();
  }
  getRoles(): Observable<UserRoles> {
    return this.http.get<UserRoles>(this.apiUrl + '/users/userroles/');
  }
  // getRolesById(id: string): Observable<UserRoles> {
  //   return this.http.get<UserRoles>(this.apiUrl + '/users/userroles/' + id);
  // }
  sendResetPasswordEmail(email: string) {
    console.log(email);
    return this.http.get(this.resetUrl + '/SendResetPasswordEmail?email=' + email);
  }
  getUsers(orderBy: string, reverse: boolean): Observable<HttpResponse<Object>> {
    var filters = '';
    if(orderBy != null && reverse != null) filters = '?OrderBy=' + orderBy + '&Reverse=' + reverse;
    return this.http.get<Object>(
      this.apiUrl + '/users' + filters, { observe: 'response' });
  }
  getUsersBySearch(value: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.apiUrl + '/users?searchquery=' + value, { observe: 'response' });
  }
  getUsersByPage(maxItems: number, pageNumber: number,orderBy: string, reverse: boolean): Observable<HttpResponse<any>> {
    return this.http.get(this.apiUrl + '/users?pageSize=' + maxItems + '&pageNumber=' + pageNumber + '&OrderBy=' + orderBy + '&Reverse=' + reverse, { observe: 'response' })
  }
  getUsersByPagedSearch(value: string, maxItems: number, pageNumber: number,orderBy: string, reverse: boolean): Observable<HttpResponse<any>> {
    value = value.trim();
    return this.http.get<any>(this.apiUrl +
      '/users?searchquery=' + value + '&pageSize=' + maxItems + '&pageNumber=' + pageNumber  + '&OrderBy=' + orderBy + '&Reverse=' + reverse, { observe: 'response' })
  }
  getUserById(id: string): Observable<UserForUpdate> {
    // let user: User = null;
    let userForController: UserForUpdate = new UserForUpdate();

    return this.http.get<User>(this.apiUrl + '/users/' + id).pipe(
      map(dbUser => {
        userForController = automapper.map('UserModel', 'UserFromDB', dbUser);
        return userForController;
      }),
      catchError(this.handleError)
    );
  }
  getLastUrl(): Observable<HttpResponse<any>> {
    return this.http.get(this.lastUrl, { observe: 'response'});
  }
  saveUser(user: UserForCreation): Observable<User>  {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
  return this.http.post<User>(this.apiUsersUrl + '/users/', user, {headers: headers});
}

  updateUser(id: string, updateUser: UserForUpdate) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.put(this.apiUrl + '/users/' + id, updateUser)
  }
  deleteUser(id: string) {
    return this.http.delete(this.apiUrl + '/users/' + id);
  }

  importFile(file: any): Observable <CounterUsers> {
    // const headers = new HttpHeaders({'Content-Type': 'multipart/form-data; boundary="boundary"'});
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    return this.http.post<CounterUsers>(this.apiUsersUrl + '/users/uploadUsers/', formData,  {headers: headers});

  }
  handleErrorSave(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError;
    ;
  };
  handleError(error: any) {
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.log(error.headers.get);
    return Observable.throw(errMsg);
  }
}

