import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RatesComponent } from './rates.component';
import { CampersListComponent } from './campers-list/campers-list.component';
import { CampersRatesComponent } from './campers-rates/campers-rates.component';
import { SupplierListComponent } from './supplier-list/supplier-list.component';
import { CampersManualRatesComponent } from './campers-manual-rates/campers-manual-rates.component';
import { DiscountCodesComponent } from '../discount-codes/discount-codes.component';


const routes: Routes = [
  {
    path: '', component: RatesComponent, data: { title: 'Rates' },
    children: [
      {
        path: '',
        component: SupplierListComponent
      },
      {
        path: 'camperList/:id',
        component: CampersListComponent
      },
      {
        path: 'camperList/:id/camperRates/:id',
        component: CampersRatesComponent
      },
      {
        path: 'manual/:id',
        component: CampersManualRatesComponent
      }
  ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RatesRoutingModule { }
