<form>
    <h4 class="form-section"></h4>
</form>
<form class="search-bar" role="search">

    <div class="position-relative has-icon-right">
        <input type="text" class="form-control round" value="{{ searchValue }}" #search placeholder="Search" (keyup)="onSearch(search.value)">
        <div class="form-control-position">
            <i class="ft-search"></i>
        </div>
    </div>
</form>
<table class="table mt-4 table-hover table-responsive-md table-sm table-striped">
    <thead>
        <tr>
            <th style="width: 110px;">Actions</th>
            <th (click)="orderColumn('Name')">Supplier <i *ngIf="orderBy=='Name' && reverse == false" class="fas fa-caret-up"></i><i *ngIf="orderBy=='Name' && reverse == true" class="fas fa-caret-down"></i></th>
            <!-- <th>Use Flex Rates</th> -->
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let supplier of supplierList; index as id">
            <td>
                <a *ngIf="supplier.useFlexRates; else ngFlex" class="ft-edit list-action text-success" routerLink="camperList/{{ supplier.id }}" (click)="sameModule()" title="Edit"></a>
                <ng-template #ngFlex>
                    <a class="ft-edit list-action text-success" routerLink="manual/{{ supplier.id }}" (click)="sameModule()" title="Edit"></a>
                </ng-template>
            </td>
            <td>{{ supplier.name }}</td>
            <!-- <td>
          <button type="submit" class="btn-sm btn-outline-info"
            *ngIf="supplier.useFlexRates; else ngFlexButton"
            (click)="updateFlexRatesState(supplier.id, id)">
            <span class=""></span>  Use Flex
          </button>
          <ng-template #ngFlexButton>
              <button type="submit" class="btn-sm btn-outline-info"
              (click)="updateFlexRatesState(supplier.id, id)">
              <span class=""></span>  Not Use Flex
            </button>
           </ng-template>
      </td> -->
        </tr>
    </tbody>
</table>
<ngb-pagination (pageChange)="onPager($event)" class="d-flex justify-content-center" [collectionSize]="totalPages" [(page)]="page" [maxSize]="5" [boundaryLinks]="true"> </ngb-pagination>