export class BasePrice {
  id: string;
  from: Date;
  to: Date;
  price: number;
  new: boolean;
  // preparationFee: number;
  // preparationFeeBackUp = null;
  edit = false;
  priceBackUp = null;
  fromBackUp =  null;
  toBackUp = null;
  availability: string;
}
export class BasePriceToCreate {
  id: string;
  from: Date;
  to: Date;
  price: number;
  new = false;
  edit = false;
  fareId: string;
  priceBackUp: number;
  fromBackUp: Date;
  toBackUp: Date;
  // preparationFee: number;
  // preparationFeeBackUp: number;
  availability: string;
}
