export const environment = {
  production: true,
  apiRoot: 'https://devapi.worldwidecampers.com',
  apiUrl: 'https://devapi.worldwidecampers.com/api',
  apiUrlImage: 'https://devapi.worldwidecampers.com',
  apiUsersUrl: 'https://idp.worldwidecampers.com/api',
  apiReports: 'https://reportservices.worldwidecampers.com/WWC/',
  resetUrl: 'https://idp.worldwidecampers.com/account',
  isDev: true,
  openIdConnectSettings: {
    authority: 'https://idp.worldwidecampers.com/',
    client_id: 'tourmanagementclient',
    redirect_uri: 'https://devbackend.worldwidecampers.com/signin-oidc',
    scope: 'openid profile roles wwcApi',
    response_type: 'id_token token',
    post_logout_redirect_uri: 'https://devbackend.worldwidecampers.com/',
    automaticSilentRenew: true,
    silent_redirect_uri: 'https://devbackend.worldwidecampers.com/redirect-silentrenew'
  }
};