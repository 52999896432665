import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { Observable, pipe, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseService } from '../../shared/services/base.service';
import { RateToCreation, Rate, ManualRateToCreation } from './rates.model';
import { Camper } from './campers.model';
import { Office, FareOffice, ManualOffice } from './office.model';
import { Supplier } from './suppliers.model';
import { isDifferent } from '@angular/core/src/render3/util';
import { ManualPrice, SeasonDate, Season, SeasonDateToUpdate, MpList, ManualPriceToUpdate } from './modelPrice.model';



@Injectable()
export class ManualRatesService extends BaseService {
  config = new Array;
  lastUrl: string;
  searchValue: string;
  goBack: boolean;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    observe: 'response' as 'response'
  };

  constructor(public http: HttpClient) {
    super();
  }
  getCampersV1ById(id: number): Observable<HttpResponse<Camper>> {
    return this.http.get<Camper>(this.apiUrl + '/camper/' + id , { observe: 'response' });
  }
  getFaresBySupplier(idSupplier: number): Observable <Array<Rate>> {
    return this.http.get<Array<Rate>>( this.apiUrl + '/fares/manual/bySupplier/' + idSupplier);
  }
  updateAllInclusive(id: string, check: boolean): Observable <Array<Rate>> {
    return this.http.get<Array<Rate>>( this.apiUrl + '/fares/manual/AllInclusive/' + id + "/" + check);
  }
  getLastUrl(): Observable<HttpResponse<Array<Camper>>> {
    return this.http.get<Array<Camper>>(this.lastUrl, { observe: 'response'});
  }

  getSupplier(id: number): Observable<Supplier> {
    return this.http.get<Supplier>(this.apiUrl + '/suppliers/' + id);
  }
  getSuppliers(): Observable<HttpResponse<Array<Supplier>>> {
    return this.http.get<Array<Supplier>>(this.apiUrl + '/suppliers', { observe: 'response' });
  }

  getOfficeList(id: number): Observable <Array<Office>> {
    return this.http.get<Array<Office>>(this.apiUrl + '/camper/office/' + id);
  }
  updateManualFareOffice(fareOfficeId: string, fareId: string, OfficeId: number) {
    const manualOffice = new ManualOffice();
    manualOffice.Id = Number.parseInt(fareOfficeId, 10);
    manualOffice.ManualFareId = Number.parseInt(fareId, 10);
    manualOffice.supplierOfficesId = OfficeId;
    console.log(manualOffice);
    return this.http.put<ManualOffice>( this.apiUrl + '/fares/manual/Office', manualOffice);
  }

  newTariff(name: string, idSupplier: number): Observable<Rate> {
    console.log(name);
    const newTariff: ManualRateToCreation = new ManualRateToCreation();
    newTariff.name = name;
    newTariff.supplierId = idSupplier;
    console.log('New Tariff -> ', newTariff);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<Rate>(this.apiUrl + '/fares/manual/', newTariff, {headers: headers}).pipe(catchError(this.handleError))
  }
  copyTariff(name: string, idSupplier: number, idTariffToCopy: string): Observable<Rate> {
    console.log(name);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<Rate>(this.apiUrl + '/fares/manual/copy/' + name + '/' + idSupplier + '/' + idTariffToCopy,
    {headers: headers}).pipe(catchError(this.handleError))
  }
  deleteTariff(id: string) {
    return this.http.delete(this.apiUrl + '/fares/manual/' + id);
  }



  getManualPrice(idTariff: string): Observable <MpList> {
    return this.http.get<MpList>( this.apiUrl + '/fares/manualPrice/' + idTariff);
  }
  newManualPrice(manualFareId: string, idSupplier: number): Observable<Rate> {
    console.log(manualFareId);
    console.log(idSupplier);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<Rate>(this.apiUrl + '/fares/manualPrice/' + manualFareId + '/' + idSupplier, {headers: headers})
  }
  getSeasons(): Observable <Array<Season>> {
    return this.http.get<Array<Season>>(this.apiUrl + '/fares/seasons/');
  }
  getSeasonDate(idTariff: string): Observable <Array<SeasonDate>> {
    return this.http.get<Array<SeasonDate>>( this.apiUrl + '/fares/seasondate/' + idTariff);
  }
  updateSeasondate(id: number, seasonDate: SeasonDate): Observable<SeasonDate> {
    const sdToUpdate = new SeasonDateToUpdate();
    sdToUpdate.id = seasonDate.id;
    sdToUpdate.seasonId = seasonDate.seasonId;
    sdToUpdate.from = seasonDate.from;
    sdToUpdate.to = seasonDate.to;
    sdToUpdate.manualFareId = seasonDate.manualFareId;
    return this.http.put<SeasonDate>( this.apiUrl + '/fares/seasonDate/' + id, sdToUpdate);
  }
  newSeasonDate(seasonDate: SeasonDate): Observable<SeasonDate> {
    const sdToUpdate = new SeasonDateToUpdate();
    sdToUpdate.seasonId = seasonDate.seasonId;
    sdToUpdate.from = seasonDate.from;
    sdToUpdate.to = seasonDate.to;
    sdToUpdate.manualFareId = seasonDate.manualFareId;
    console.log(sdToUpdate);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<SeasonDate>(this.apiUrl + '/fares/seasonDate', sdToUpdate, {headers: headers});
  }
  deleteSeasonDate(id: string) {
    return this.http.delete(this.apiUrl + '/fares/seasonDate/' + id);
  }

  updateManualPrice(value: number, id: number) {
    const mpToUpdate = new ManualPriceToUpdate();
    mpToUpdate.id = id;
    mpToUpdate.value = value;
    return this.http.put( this.apiUrl + '/fares/manualPrice/', mpToUpdate);
  }
  handleErrorSave(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError;
    ;
  };
  handleError(error: any) {
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.log(error.headers.get);
    return throwError(errMsg);
  }
}

