import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { BreadcrumbService } from 'ng5-breadcrumb';

@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss']
})

export class FullLayoutComponent implements OnInit{
    options = {
        direction: 'ltr'
    };
    constructor(    private _route: ActivatedRoute,
      private _router: Router) {}
      ngOnInit() {
      }
}
